﻿
//possibly some common text styles or whatever can go in here.

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans');

@import "../modules/constants";
@import "../modules/mixins";


/*body::-webkit-scrollbar {
	position: relative;
	z-index: 1500;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}*/

.paragraph-end-link {
	color: #F6490D;
	text-decoration: underline;
}


/* ------------------- */
/* Button styles */

.btn {
	text-align: center;
	letter-spacing: -0.25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFFFFF;
	font-family: $header-font;
	font-size: 13px;
	font-weight: bold;
}

.btn-sign-up {
	
	width: 175px;
	height: 42px;
}

.btn-medium {
	width: 130px;
	height: 42px;
}

.btn-large {
	height: 61px;
	width: 203px;
	box-shadow: 6px 6px 0 0 #F2F2F2;
}

.btn-red {
	background-color: $red-button;	
	color:white !important;
}

.btn-red:hover {
	background-color: $red-button-hover;
}

.btn-tan {
	background-color: $light-tan;
	color: $paragraph-font-darker-gray;
}

.btn-transparent {
	/*background-color: rgba(255,255,255,0.75);*/
	color: $red-button;
	border: 2px solid $red-button;
}

.btn-transparent:hover {
	background-color: rgba(255,255,255,0.12);
}

.sign-up-btn {
	text-align: center;
	letter-spacing: -0.25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFFFFF;
	font-family: $header-font;
	font-size: 13px;
	font-weight: bold;
}

/*-----------------------*/