﻿@import "../modules/constants";
@import "../modules/mixins";

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    height: 71px;
  }

  @include respond-min($breakpoint-lg) {
    /*height: 100px;*/
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .logo-link {
    display: block;
    height: inherit;
    width: 100%;
  }

  .navbar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    align-items: flex-start;
    height: 50%;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      /*align-items: center;*/
    }

    @include respond-min($breakpoint-sm) {
      /*margin-top: 4%;*/
    }

    @include respond-min($breakpoint-xxl) {
      margin-top: 0%;
    }

    .navbar-logo-section-homepage.base {
      background-image: url("../images/commonAssets/trulyLogos/Group@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 5vw;
      width: 16vw;
      min-width: 111px;

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        background-image: url("../images/homePageAssets/cut-out-logo@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        height: 106px;
        width: 79%;
        margin-top: -35px;
      }

      @include respond-range(481px, $breakpoint-md) {
        /*background-size: 109.5px 27.5px;
				height: 27.5px;
				width: 109.5px;
				margin-left: 3%;*/
      }

      @include respond-range($breakpoint-sm, $breakpoint-lg) {
        /*background-size: 136.8px 34.4px;
				height: 34.4px;
				width: 136.8px;*/
      }

      @include respond-range($breakpoint-xsm, 550px) {
        position: relative;
        right: 6px;
        bottom: 5px;
      }

      @include respond-range(481px, 1919px) {
        margin-left: 5%;
      }

      @include respond-min($breakpoint-xxl) {
        background-image: url("../images/homePageAssets/cut-out-logo@2x.png");
        background-repeat: no-repeat;
        height: 309px;
        width: 43%;
        background-size: contain;
        margin-top: -96px;
      }
    }

    .navbar-logo-section-homepage.IL {
      background-image: url("../images/commonAssets/trulyLogos/logo-Truly-orange-white.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 5vw;
      min-height: 20px;
      max-height: 95px;
      width: 16vw;
      min-width: 111px;
      margin-left: 5%;

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        /*background-image: url("../images/homePageAssets/cut-out-logo@2x.png");
                background-repeat: no-repeat;
                background-size: contain;
                height: 106px;
                width: 79%;
                margin-top: -35px;*/
      }

      @include respond-range(481px, $breakpoint-md) {
        /*background-size: 109.5px 27.5px;
				height: 27.5px;
				width: 109.5px;
				margin-left: 3%;*/
      }

      @include respond-range($breakpoint-sm, $breakpoint-lg) {
        /*background-size: 136.8px 34.4px;
				height: 34.4px;
				width: 136.8px;*/
      }

      @include respond-range($breakpoint-xsm, 550px) {
        position: relative;
        right: 6px;
        bottom: 5px;
      }
      /* @include respond-range(481px, 1919px) {
                margin-left: 5%;
            }*/

      @include respond-min($breakpoint-xxl) {
        /*background-image: url("../images/homePageAssets/cut-out-logo@2x.png");
                background-repeat: no-repeat;
                height: 309px;*/
        width: 43%;
        /* background-size: contain;
                margin-top: -96px;*/
      }
    }

    .navbar-logo-section-tech-page,
    .navbar-logo-section-about-page,
    .navbar-logo-section-locations-page,
    .navbar-logo-section-privacy-page,
    .navbar-logo-section-resources-page {
      background-image: url("../images/commonAssets/trulyLogos/truly-logo-white@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 58px;
      width: 180px;
      max-width: 180px;

      @include respond-range($breakpoint-xxsm, 600px) {
        background-size: contain;
        height: 34px;
        width: 37%;
        margin-left: 3%;
      }

      @include respond-range(600px, $breakpoint-md) {
        height: 6vw;
        margin-left: 3%;
        background-size: contain;
        width: 37%;
        /*margin-top: 1%;*/
      }

      @include respond-min($breakpoint-md) {
        margin-left: 3%;
      }

      @include respond-min($breakpoint-xxl) {
        height: 84px;
        width: 11%;
        background-size: contain;
        /*margin-top: 120px;*/
      }
    }

    .navbar-logo-section-signup-page {
      background-image: url("../images/commonAssets/trulyLogos/Group@2x.png");
      background-repeat: no-repeat;
      height: 46px;
      background-size: contain;
      height: 34px;
      width: 37%;
      margin-left: 3%;
    }
    .navbar-logo-section-privacy-page {
      background-image: url("../images/commonAssets/trulyLogos/Group@2x.png");
      height: 46px;

      @include respond-range($breakpoint-xxsm, 1065px) {
        background-size: contain;
        height: 34px;
        width: 37%;
        margin-left: 3%;
      }
    }

    .navbar-menu-item-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 930px;
      max-width: 1100px;

      @include respond-min($breakpoint-xxl) {
        margin-right: 2%;
      }

      @include respond-min(2500px) {
        margin-right: 5%;
      }

      @include respond-max(1400px) {
        display: none;
      }

      .navbar-menu-item {
        font-family: $header-font;
        font-weight: 600;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;

        .homepage-link {
          color: #ffffff;
          opacity: 0.8;
        }

        .homepage-link:hover {
          opacity: 1;
        }

        .about-page-link,
        .locations-page-link,
        .services-page-link,
        .tech-page-link,
        .resources-page-link {
          color: rgb(185, 190, 193) !important;
          opacity: 0.8;
        }

        .about-page-link:hover,
        .locations-page-link:hover,
        .services-page-link:hover,
        .tech-page-link:hover,
        .resources-page-link:hover {
          opacity: 1;
        }

        .privacy-page-link {
          color: #a9afc1;
        }

        .privacy-page-link:hover {
          color: $title-font-blue;
        }

        .active {
          color: #ffffff !important;
          opacity: 1;
        }

        .active.get-quote-link {
          color: #ffffff !important;
          opacity: 0.8;
        }
      }

      .contact-us-btn-homepage,
      .contact-us-btn-tech-page,
      .contact-us-btn-about-page,
      .contact-us-btn-locations-page,
      .contact-us-btn-privacy-page,
      .contact-us-btn-resources {
        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          width: 96px;
          height: 31px;
          font-size: 11px;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-top: 5%;
          margin-right: 5px;
        }

        @include respond-min(481px) {
          margin-right: 2%;
        }

        @include respond-min($breakpoint-xxl) {
          margin-right: 4%;
        }
      }

      .navbarLineSeperator {
        height: 50px;
        width: 1px;
        background-color: #f6490d;
      }

      .signIn {
        color: #f6490d;
      }
    }

    #homepage-menu-item-container {
      @include respond-min(1725px) {
        margin-right: 2%;
      }

      @include respond-min($breakpoint-xxl) {
        margin-left: 5%;
      }
    }

    .navbar-hamburger {
      display: block;
      float: right;
      width: 22px;
      cursor: pointer;
      margin-right: 4%;

      @include respond-min(1401px) {
        display: none;
      }

      .hamburger-bar-homepage,
      .hamburger-bar-tech-page,
      .hamburger-bar-about-page,
      .hamburger-bar-privacy-page,
      .hamburger-bar-resources-page,
      .hamburger-bar-signup-page {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: $red-button;
      }

      .hamburger-bar-locations-page {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: #ffffff;
      }
    }
  }

  .navbar-mobile-menu-open {
    height: 100vh;
    width: 100vw;
    background-color: $light-tan;
    transition: 0.5s;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: auto;

    .red-logo {
      background-image: url("../images/commonAssets/trulyLogos/Group@2x.png");
    }

    .close-mobile-menu-icon {
      color: black;
      font-size: 34px;
      line-height: 0.5;
      float: right;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      margin-right: 4%;
    }

    .navbar-mobile-menu-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: space-around;
      margin-bottom: 50px;

      .navbar-menu-item {
        font-family: $header-font;
        color: rgb(185, 190, 193);
        font-weight: 600;
        font-size: 13px;
      }

      .active {
        color: $title-font-blue !important;
      }

      .active.get-quote-link {
        color: $paragraph-font-gray;
      }

      .navbar-menu-item:hover {
        color: $title-font-blue !important;
        cursor: pointer;
      }

      a:hover {
        color: $title-font-blue !important;
      }

      .menu-item-margin {
        margin-top: 6%;

        @include respond-min($breakpoint-sm) {
          margin-top: 46px;
        }
      }

      .contact-us-btn {
        background-color: $red-button;
        width: 130px;
        height: 42px;
        text-align: center;
        letter-spacing: -0.25px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-family: $header-font;
        font-size: 13px;
        font-weight: bold;
      }

      .contact-us-btn:hover {
        background-color: $red-button-hover;
      }

      .btn-transparent:hover {
        color: #ffffff;
        background: $red-button-hover;
      }

      .navbarLineSeperatorMob {
        height: 2px;
        width: 60px;
        background-color: #999999;
        margin-top: 8%;
      }

      .signIn {
        color: #f6490d;
        margin-top: 30px;

        @include respond-min($breakpoint-sm) {
          margin-top: 46px;
        }
      }
      .signup-btn {
        color: white !important;
      }
      .signup-btn:hover {
        color: white !important;
      }
    }

    .navbar-hamburger {
      display: block;
      float: right;
      width: 22px;
      cursor: pointer;
      margin-right: 4%;

      @include respond-min($breakpoint-sm) {
        display: none;
      }

      .hamburger-bar-homepage,
      .hamburger-bar-tech-page,
      .hamburger-bar-about-page,
      .hamburger-privacy-page,
      .hamburger-bar-resources-page .hamburger-bar-signup-page {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: $red-button;
      }

      .hamburger-bar-locations-page {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: #ffffff;
      }
    }
  }

  .navbar-mobile-menu-closed {
    display: none;
    height: 0;
    @include transition(0.5s);
  }
}

#homepage-navbar-container {
  @include respond-range($breakpoint-xxsm, 600px) {
    height: 71px;
  }

  #homepage-navbar-content {
    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      align-items: initial;
    }

    @include respond-min($breakpoint-xxl) {
      justify-content: flex-start;
    }
  }
}

#locations-page-navbar-container {
  max-height: 614px;

  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    height: 40px;
  }

  #locations-page-navbar-content {
    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      margin-top: 25px;
    }
  }
}

#privacy-page-navbar-container {
  box-shadow: 0 4px 6px 0 #fafafa;
  border-bottom: 1px solid #f2f2f2;
  height: 112px;

  @include respond-range($breakpoint-xxsm, 1065px) {
    height: 71px;
  }

  #privacy-page-navbar-content {
    align-items: center;
  }
}

.navbar-container-scrolling {
  position: fixed;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;

  @include respond-range($breakpoint-xxsm, $breakpoint-md) {
    height: 71px;
  }

  @include respond-min($breakpoint-lg) {
    height: 100px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    color: $title-font-blue !important;
  }

  .navbar-content {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      align-items: center;
    }

    @include respond-min($breakpoint-xxl) {
      margin-top: 0%;
    }

    .navbar-logo-section {
      background-image: url("../images/commonAssets/trulyLogos/Group@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      height: 49px;
      width: 180px;
      max-width: 180px;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        background-repeat: no-repeat;
        background-size: contain;
        height: 34px;
        width: 37%;
        margin-left: 3%;
      }

      @include respond-range(993px, 1919px) {
        margin-left: 5%;
      }

      @include respond-min($breakpoint-xxl) {
        background-repeat: no-repeat;
        height: 49px;
        width: 259px;
        background-size: contain;
        margin-left: 7.5%;
      }

      .logo-link {
        display: block;
        height: inherit;
        width: 100%;
      }

      .scroll {
        cursor: pointer;
      }
    }

    .navbar-menu-item-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 930px;
      max-width: 1100px;

      @include respond-min($breakpoint-xxl) {
        margin-right: 2%;
      }

      @include respond-min(2500px) {
        margin-right: 5%;
      }

      @include respond-max(1400px) {
        display: none;
      }
      @include respond-min(1725px) {
        margin-right: 2%;
      }

      @include respond-min($breakpoint-xxl) {
        margin-left: 5%;
      }

      .navbar-menu-item {
        color: rgb(185, 190, 193);
        font-family: $header-font;
        font-weight: 600;
        font-size: 13px;

        @include respond-max($breakpoint-md) {
          font-size: 11px;
          margin-top: 2px;
        }

        .active {
          color: $title-font-blue;
        }
      }

      .navbar-menu-item:hover {
        color: $title-font-blue;
        cursor: pointer;
      }

      .contact-us-btn {
        background-color: $red-button;
        width: 130px;
        height: 42px;
        text-align: center;
        letter-spacing: -0.25px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-family: $header-font;
        font-size: 13px;
        font-weight: bold;

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          width: 96px;
          height: 31px;
          font-size: 11px;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-top: 5%;
          margin-right: 5px;
        }

        @include respond-min(481px) {
          margin-top: -5px;
          margin-right: 2%;
        }

        @include respond-min($breakpoint-xxl) {
          margin-top: 2px;
          margin-right: 4%;
        }
      }

      .contact-us-btn:hover {
        background-color: $red-button-hover;
      }

      .btn-transparent:hover {
        color: #ffffff;
        background: $red-button-hover;
      }
      .navbarLineSeperator {
        height: 50px;
        width: 1px;
        background-color: #f6490d;
      }
      .signIn {
        color: #f6490d;
      }
    }

    .navbar-hamburger {
      display: block;
      float: right;
      width: 22px;
      cursor: pointer;
      margin-right: 4%;

      @include respond-min(1401px) {
        display: none;
      }

      .hamburger-bar-homepage,
      .hamburger-bar-tech-page,
      .hamburger-bar-locations-page,
      .hamburger-bar-about-page,
      .hamburger-bar-privacy-page,
      .hamburger-bar-resources-page {
        height: 3px;
        margin-bottom: 5px;
        border-radius: 2px;
        background-color: $red-button;
      }
    }
  }

  .navbar-mobile-menu-open {
    height: 100vh;
    width: 100vw;
    background-color: $light-tan;
    transition: 0.5s;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: auto;

    .close-mobile-menu-icon {
      color: black;
      font-size: 34px;
      line-height: 0.5;
      float: right;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      margin-right: 4%;
    }

    .navbar-mobile-menu-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 50px;

      .navbar-menu-item {
        font-family: $header-font;
        color: rgb(185, 190, 193);
        font-weight: 600;
        font-size: 13px;
        margin-top: 6%;

        @include respond-min($breakpoint-sm) {
          margin-top: 46px;
        }
      }

      .menu-item-margin {
        margin-top: 6%;

        @include respond-min($breakpoint-sm) {
          margin-top: 30px;
        }
      }

      .active {
        color: $title-font-blue !important;
      }

      .active.get-quote-link {
        color: $paragraph-font-gray;
      }

      .navbar-menu-item:hover {
        color: $title-font-blue !important;
        cursor: pointer;
      }

      a:hover {
        color: $title-font-blue !important;
      }

      .contact-us-btn {
        background-color: $red-button;
        width: 130px;
        height: 42px;
        text-align: center;
        letter-spacing: -0.25px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-family: $header-font;
        font-size: 13px;
        font-weight: bold;
        margin-top: 6%;

        @include respond-min($breakpoint-sm) {
          margin-top: 46px;
        }
      }

      .contact-us-btn:hover {
        background-color: $red-button-hover;
      }
    }
  }

  .navbar-mobile-menu-closed {
    display: none;
    height: 0;
    @include transition(0.5s);
  }
}

#hide-navbar {
  display: none !important;
}

.more-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  transition: 0.3s ease all;
  background: white;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 2;
  overflow: hidden;
  width: 240px;
  box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.1);

  .more-menu-title {
    width: 65px;
    text-align: left;
    background-image: url("../images/commonAssets/commonIcons/dropdown-arrow-contract@2x.png");
    background-position: right;
    background-size: 18%;
    background-repeat: no-repeat;
    color: $red-button;
    font-size: 13px;
    font-family: $header-font;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .options {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    width: 100%;
  }

  .more-menu-option {
    font-size: 11px;
    color: #9b9b9b;
    padding: 8px 0;
    font-family: $header-font;
    font-weight: 500;
  }

  .more-menu-option.active {
    color: $red-button;
  }

  .more-menu-option.bordered {
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
  }
}

.more-btns {
  position: relative;

  .more-btn {
    color: #b9bec1;
    opacity: 0.8;
    width: 65px;
    text-align: left;
    background-position: right;
    background-size: 18%;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .more-btn.down-arrow {
    background-image: url("../images/commonAssets/commonIcons/dropdown-arrow-expand-white.png");
  }

  .more-btn:hover {
    opacity: 1;
  }

  .more-btn.scrolling {
    background-image: url("../images/commonAssets/commonIcons/icon-dropdownArrow-gray.png");
    color: #b9bec1;
  }

  .more-btn.scrolling:hover {
    color: $title-font-blue;
  }
}

.more-menu-container.open {
  height: 190px;
  z-index: 2;
  opacity: 1;
}

.more-menu-container.closed,
.mobile-more-menu.closed {
  visibility: hidden;
  height: 0px;
  opacity: 0;
}

.more-btn.mobile {
  width: 100%;
  text-align: center;
  background-position: right;
  background-size: 14%;
  background-repeat: no-repeat;
  width: 69px;
  color: #999999;
}

.more-btn.mobile.up-arrow {
  background-image: url("../images/commonAssets/commonIcons/dropdown-arrow-contract@2x.png");
  color: $red-button;
}

.more-btn.mobile.down-arrow {
  background-image: url("../images/commonAssets/commonIcons/dropdown-arrow-expand@2x.png");
}

.mobile-more-menu {
  display: flex;
  flex-direction: column;
  transition: 0.3s ease all;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $header-font;
    color: #999999;
    font-weight: 600;
    font-size: 13px;
    height: 100%;
    justify-content: space-around;

    .more-menu-option:nth-child(2) {
      padding: 10px 0;
    }
  }
}

.mobile-more-menu.open {
  height: 90px;
  opacity: 1;
  padding: 0;
}

.navbar-menu-item.mob {
  transition: 0.3s ease all;
  height: 19px;
  opacity: 1;
}

.navbar-menu-item.closed {
  opacity: 0;
  height: 0;
  margin: 0 !important;
  visibility: hidden;
}

#mob-logo-link::after {
  content: " ";
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
}
