﻿@import "../modules/constants";
@import "../modules/mixins";

.locations-page-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  align-content: space-between;
  justify-content: center;

  .navbar-hero-container {
    background-color: $red-button;
    height: 32vw;
    max-height: 614px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../images/locationsPageAssets/locations-hero@3x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      height: 172px;
    }

    .locations-page-hero-container {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      /*background-image: url("../images/locationsPageAssets/locations-hero@3x.jpg");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			background-position-y: bottom;*/
      /*max-height: 400px;*/
      width: 95%;
      max-width: 1980px;
      height: auto;
      flex-grow: 1;

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        display: block;
      }
      /*@include respond-range($breakpoint-xxsm,600px) {
				background-size: cover;
				height: 75vw;
			}

			@include respond-range(600px, $breakpoint-sm) {
				background-size: cover;
				height: 65vw;
			}*/
      /*@include respond-min($breakpoint-xxsm) {
				background-size: contain;
				height: 21vw;
			}*/
      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        height: 111px;
        background-size: cover;
      }

      @include respond-min(1954px) {
        /*background-size: cover;
				background-position: center;
				background-position-y: top;
				margin: 0 auto;*/
      }

      .hero-text-container {
        margin-bottom: 7%;
        width: 81%;
        max-width: 855px;

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          width: 400px;
        }

        @include respond-range($breakpoint-md, $breakpoint-lg) {
          /*width: 900px;*/
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-top: 7%;
          margin-left: 4%;
        }

        @include respond-range($breakpoint-xsm, 600px) {
          /*margin-bottom: 10%;*/
        }

        @include respond-min($breakpoint-xxl) {
          margin-bottom: 128px;
        }

        h1 {
          color: #ffffff;
          font-family: $header-font;
          font-size: 66px;
          margin: 0 0 0 0;
          font-weight: 600;

          @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
            font-size: 40px;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            margin-top: 40px;
          }
        }
      }
    }
  }

  .locations-body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*height: 2032px;*/
    background-color: $light-tan;
    /*max-height: 2032px;*/
    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      height: auto;
      max-height: none;
    }

    .locations-sections-container {
      width: 95%;
      /*height: 211%;*/
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      bottom: 70px;
      /*max-height: 1869px;*/
      max-width: 1400px;
      min-height: 700px;

      @include respond-range($breakpoint-xxsm, 500px) {
        width: 94%;
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        height: auto;
        justify-content: space-evenly;
        max-height: none;
        bottom: 26px;
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        bottom: 4px;
        margin-top: 80px;
      }

      @include respond-range($breakpoint-xsm, 650px) {
        bottom: 15px;
      }

      @include respond-range($breakpoint-md, 1080px) {
        /*bottom: 6%;*/
      }

      .visibility-location-container {
        width: 100%;
        display: flex !important;
        /*height: 50%;*/
        /*height: 441px;*/
        max-width: 1058px;
        max-height: 441px;
        margin-bottom: 30px;

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          width: 471px;
          height: auto;
          min-height: 700px;
          max-height: none;
          margin-bottom: 20px;
        }

        @include respond-range($breakpoint-xxsm, 500px) {
          width: 100%;
        }

        .location-card-container {
          width: 100%;
          display: flex !important;
          height: 100%;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
          background-color: #ffffff;
          max-width: 1058px;
         
          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            max-height: none;
            height: auto;
            flex-direction: column;
            min-width: 300px;
          }

          .google-map {
            width: 50%;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 100%;
              height: 50%;
              min-height: 350px;
            }
          }

          .map-placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #e8eaed;
          }

          .location-card {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            text-align: center;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 100%;
              height: 50%;
            }

            @include respond-range($breakpoint-xxsm, 420px) {
              height: auto;
              flex: 1;
            }

            .location-card-text {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;

              @include respond-min($breakpoint-md) {
                height: 276px;
              }

              @include respond-range($breakpoint-xxsm, 420px) {
                /*height: 239px;*/
                justify-content: space-between;
              }

              .location-icon {
                background-image: url("../images/locationsPageAssets/Fill 591 + Fill 592 + Fill 593@2x.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-position-y: top;
                width: 50px;
                height: 50px;
                margin-top: 20px;
                flex-shrink: 0;
              }

              p {
                color: $paragraph-font-gray;
                font-family: $paragraph-font;
                font-size: 16px;
                font-weight: 500;

                @include respond-max(335px) {
                  font-size: 15px;
                }
              }

              .first-address {
                margin-bottom: 0 !important;

                @include respond-range($breakpoint-xxsm, 420px) {
                  margin-bottom: 6px !important;
                }

                @include respond-range(420px, $breakpoint-md) {
                  margin-bottom: 10px !important;
                }
              }

              h3 {
                color: $title-font-blue;
                font-family: $paragraph-font;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: -0.33px;
                padding: 0 8px;
              }

              .location-contact-container {
                display: flex;
                justify-content: center;
                flex-direction: column;

                @include respond-range($breakpoint-xxsm, 420px) {
                  flex-direction: column;
                  align-items: center;
                  height: auto;
                  justify-content: space-between;
                }

                p:first-child {
                  margin-bottom: 0;
                }

                .toll-free-numbers-container {
                  display: flex;
                  justify-content: space-between;
                  height: 23px;
                  width: 100%;

                  .toll-free-number {
                    color: inherit;
                  }

                  .toll-free-number:hover {
                    color: #827d7d;
                  }

                  .toll-free-or-margin {
                    margin-left: 3px;
                    margin-right: 3px;
                  }
                }

                .direct-fax-numbers {
                  display: flex;
                  justify-content: center;

                  @include respond-range($breakpoint-xxsm, 420px) {
                    flex-direction: column;
                    align-items: center;
                  }

                  .direct-number-container {
                    display: flex;

                    .direct-phone-number {
                      color: inherit;
                      margin-left: 5px;
                    }

                    .direct-phone-number:hover {
                      color: #827d7d;
                    }
                  }

                  .locations-vertical-divider {
                    display: none;

                    @include respond-min(421px) {
                      display: inline;
                      margin: 0px 5px;
                      font-weight: 500;
                      color: $paragraph-font-gray;
                    }
                  }

                  .location-contact-numbers {
                  }
                }
              }
            }

            a {
              text-decoration: none;
            }

            .location-directions-btn {
              width: 130px;
              background-color: $red-button;
              height: 42px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: $header-font;
              font-size: 12px;
              font-weight: bold;
              margin-bottom: 25px;
              cursor: pointer;
              margin-top: 25px;
            }

            .location-directions-btn:hover {
              background-color: $red-button-hover;
            }
          }
        }
      }

      .visibility-location-container:nth-last-child(2) {
        margin-bottom: 0;
      }

      .hidden.transition {
        visibility: visible;
      }

      .animating.out.visible.transition {
        animation: none;
      }
    }

    h2 {
      color: $title-font-blue;
      font-family: $header-font;
      font-size: 37px;
      font-weight: 300;
      /*height: 14%;*/
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      position: relative;
      top: 35px;
      /*padding-bottom: 2%;*/
      @include respond-max($breakpoint-md) {
        padding-bottom: 0;
        top: 0;
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        width: 95%;
        height: auto;
        text-align: center;
        font-size: 30px;
        /*margin-bottom: 69px;*/
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        font-size: 26px;
        /*margin-bottom: 44px;*/
      }
    }
  }
}
