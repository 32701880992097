﻿@import "../modules/constants";
@import "../modules/mixins";

.cookie-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.75);
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 999;
	min-height: 90px;

	.cookie-content {
		/*flex: 1 0 375px;*/
		margin: 15px;
		color: #FFFFFF;
		font-family: $paragraph-font;
		font-size: 15px;
		font-weight: 600;
		line-height: 25px;
		max-width: 615px;

		a {
			color: white;
			text-decoration: underline;
		}

		a:hover {
			color: white;
		}
	}

	.cookie-btn {
		background-color: $red-button;
		width: 130px;
		height: 42px;
		text-align: center;
		letter-spacing: -0.25px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #FFFFFF;
		font-family: $header-font;
		font-size: 13px;
		font-weight: bold;
		margin: 15px;
		border: none;

		@include respond-max(804px) {
			margin-top: 0;
		}
	}

	.cookie-btn:hover {
		background-color: $red-button-hover;
	}
}
