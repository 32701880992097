﻿
//---------------------------------
//fonts
//---------------------------------

$font-sans: 'Open Sans', 'sans-serif';
$header-font: Poppins, 'sans-serif';
$paragraph-font: Proxima-nova, 'sans-serif';

//---------------------------------
//colors
//---------------------------------

$lightest: #FFF;
$light-gray: #E1E1E1;
$lighter-gray: #F2F2F2;
$light-tan: #FAF9F0;
$red-button: #F6490D;
$red-button-hover: #FF4E10;
$paragraph-font-gray: #999999;
$paragraph-font-darker-gray: #D1D0C7;
$title-font-blue: #34406B;
$darkest-blue: #383D46;

//-------------------------
$text-lightest: $lightest;
$light-divider-lines: $light-gray;

//---------------------------------
//breakpoints
//---------------------------------

$breakpoint-xxsm: 320px;
$breakpoint-xsm: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-sml: 1440px;
$breakpoint-xl: 1600px;
$breakpoint-xxl: 1920px;
