﻿@import "../modules/constants";
@import "../modules/mixins";

.homepage-join-container {
  height: auto;
  width: 100%;
  background-position: top, bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  align-content {
    text-decoration: none;
  }

  @include respond-range($breakpoint-xsm, $breakpoint-sm) {
    margin-top: -8%;
  }

  @include respond-range($breakpoint-sm, $breakpoint-md) {
    margin-top: -12%;
  }

  .join-container {
    width: 100%;
    height: 40%;
    background-color: #383d46;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include respond-min(1200px) {
      height: 44%;
    }

    .join-us-content-container {
      height: 200px;
      text-align: center;

      @include respond-range($breakpoint-xxsm, 390px) {
        margin-bottom: 10%;
      }

      @include respond-range(390px, 401px) {
        margin-bottom: 0;
      }

      @include respond-range(401px, 426px) {
        margin-bottom: 10%;
      }

      @include respond-range(427px, $breakpoint-md) {
        margin-bottom: 5%;
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
        margin-top: -5%;
      }

      @include respond-range($breakpoint-sm, $breakpoint-md) {
        margin-top: 0;
      }

      @include respond-range($breakpoint-xxsm, 992px) {
        /*margin-bottom: 5%;*/
        width: 90%;
        text-align: left;
      }

      @include respond-max(520px) {
        width: 92.4%;
      }

      @include respond-min($breakpoint-md) {
        margin-top: 4%;
      }

      @include respond-min($breakpoint-xxl) {
        margin-top: 77px;
      }

      .join-us-text-container {
        @include respond-max(520px) {
          display: none;
        }

        .join-title {
          color: #f2f2f2;
          font-family: $paragraph-font;
          font-size: 58px;
          line-height: 70px;

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            font-size: 25px;
          }

          @include respond-range($breakpoint-xsm, $breakpoint-sm) {
            font-size: 35px;
          }
        }

        .small {
          font-weight: 300;
        }

        .big {
          font-weight: bold;
        }

        .join-text-paragraph {
          color: #ffffff;
          font-family: $paragraph-font;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -0.27px;
          line-height: 33px;
          margin-top: 28px;

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            width: 100%;
            font-size: 15px;
            text-align: center;
            margin-top: 6%;
          }

          @include respond-range($breakpoint-md, $breakpoint-md) {
            /*margin-left: 2%;*/
          }

          @include respond-range($breakpoint-xxsm, 710px) {
            width: 50%;
          }

          @include respond-range(710px, $breakpoint-md) {
            width: 60%;
          }

          #truly-email-link {
            color: $red-button;
          }

          #truly-email-link:hover {
            color: $red-button-hover;
          }
        }
      }

      .troole-icon-container {
        display: flex;
        flex-direction: column;
        float: right;
        position: relative;
        left: 156px;
        bottom: 256px;

        @include respond-max(520px) {
          display: none;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          left: -5px;
          bottom: 250px;
        }

        @include respond-range(520px, 595px) {
          bottom: 325px;
        }

        @include respond-range(596px, 725px) {
          bottom: 290px;
        }
        /*@include respond-range(596px, 725px) {
					bottom: 270px;
				}*/
        @include respond-range(993px, 1150px) {
          flex-direction: column;
          left: 135px;
        }

        .troole-icon {
          background-image: url("../images/commonAssets/Group 3@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 205px;
          height: 326px;
        }

        .text-link-container {
          display: flex;

          @include respond-range($breakpoint-md, 1075px) {
            text-align: left;
          }

          .meet-troole-text-link {
            color: #ffffff;
            font-family: $paragraph-font;
            font-size: 16px;
            font-weight: bold;
            margin-top: -30px;
            position: relative;
            right: -17px;
            width: 205px;

            @include respond-range($breakpoint-md, 1150px) {
              margin-top: -30px;
              right: 36px;
            }
          }

          .up-arrow-icon {
            /* Keep "display:none" until we get the popup blurb about Troole done*/
            display: none;
            background-image: url("../images/commonAssets/commonIcons/up-arrow@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 16px;
            height: 13px;
            position: relative;
            bottom: 8px;
            left: 10px;

            @include respond-max(1150px) {
              bottom: 8px;
              left: -4px;
            }

            @include respond-range($breakpoint-md, 1075px) {
              left: -20px;
            }
          }
        }
      }

      .troole-icon-text-small-screen {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-range($breakpoint-xsm, 520px) {
          margin-top: 15px;
        }

        @include respond-min(520px) {
          display: none;
        }

        .join-title-container {
          display: flex;
          width: 218px;
          justify-content: space-between;

          @include respond-max(479px) {
            width: 156px;
          }

          .join-title {
            color: #f2f2f2;
            font-family: $paragraph-font;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              font-size: 25px;
            }

            @include respond-range($breakpoint-xsm, $breakpoint-sm) {
              font-size: 35px;
            }
          }

          .small {
            font-weight: 300;
          }

          .big {
            font-weight: bold;
          }
        }

        .icon-text-container {
          display: flex;

          .troole-icon {
            background-image: url("../images/commonAssets/Group 3@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 128px;
            height: 220px;
            position: relative;
            right: 6px;
          }

          .small-screen-text-container {
            width: 69%;

            @include respond-max(400px) {
              margin-top: 10px;
            }

            .join-text-paragraph {
              color: #ffffff;
              font-family: $paragraph-font;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: -0.27px;
              line-height: 33px;
              margin-top: 28px;

              @include respond-max(400px) {
                font-size: 16px;
                margin-top: 0;
              }
            }

            .meet-troole-text-link-container {
              display: flex;
              justify-content: space-between;
              width: 198px;
              margin-top: 15px;

              @include respond-min(400px) {
                width: 220px;
              }

              .up-arrow-icon {
                /* Keep "display:none" until we get the popup blurb about Troole done*/
                display: none;
                background-image: url("../images/commonAssets/commonIcons/up-arrow@2x.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 41px;
                height: 13px;
                margin-top: 9px;
                top: 33px;
                position: relative;
                right: 51px;
              }
            }
          }
        }
      }
    }

    .join-input-container {
      width: 100%;
      display: flex;
      justify-content: center;

      .join-input-content {
        position: relative;
        top: 40px;
        height: 86px;
        background-color: white;
        width: 92.4%;
        max-width: 1062px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);

        @include respond-max($breakpoint-xsm) {
          height: 60px;
          top: 30px;
        }

        .join-input-left-items {
          display: flex;
          align-items: center;

          .email-icon {
            margin: 0px 24px;
            width: 24px;
            height: 18px;
            background-image: url("../images/homePageAssets/icon-email@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @include respond-max($breakpoint-xsm) {
              display: none;
            }
          }

          .email-input,
          .email-icon:focus {
            width: 200px;
            border: none;
            outline: none;
            color: #d0d0d0;
            font-family: $header-font;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.5px;

            @include respond-max($breakpoint-xsm) {
              margin-left: 10px;
            }
          }
          /* This is for styling the input placeholder across different browsers */
          ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #d0d0d0;
            font-family: $header-font;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.5px;
          }

          ::-moz-placeholder {
            /* Firefox 19+ */
            color: #d0d0d0;
            font-family: $header-font;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.5px;
          }

          :-ms-input-placeholder {
            /* IE 10+ */
            color: #d0d0d0;
            font-family: $header-font;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.5px;
          }

          :-moz-placeholder {
            /* Firefox 18- */
            color: #d0d0d0;
            font-family: $header-font;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.5px;
          }
        }

        .join-continue-btn {
          width: 206px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $red-button;
          color: #ffffff;
          font-family: $header-font;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0.5px;
          cursor: pointer;

          @include respond-max($breakpoint-xsm) {
            width: 130px;
          }
        }

        .join-continue-btn:hover {
          background-color: $red-button-hover;
          cursor: pointer;
        }
      }
    }

    .join-us-bottom-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      width: 100%;

      @include respond-min(1100px) {
        margin-top: 44%;
      }

      @include respond-min(1420px) {
        margin-top: 38%;
      }

      @include respond-min(1800px) {
        margin-top: 33%;
      }

      @include respond-min(2600px) {
        margin-top: 28%;
      }

      @include respond-min(3000px) {
        margin-top: 26%;
      }

      .locations-container {
        width: 90%;
        background: white;
        max-width: 1100px;

        @include respond-range(600px, 1100px) {
          max-width: 575px;
        }

        @include respond-min(1100px) {
          display: flex;
          justify-content: space-evenly;
          height: 464px;
          margin-top: -47%;
          position: relative;
          top: 109%;
        }

        @include respond-min(1420px) {
          margin-top: -40%;
        }

        @include respond-min(1800px) {
          top: 119%;
        }

        @include respond-min(2200px) {
          top: 130%;
        }

        @include respond-min(2600px) {
          top: 133%;
        }

        .locations-border {
          width: 80%;
          margin-left: 8%;
          border-bottom: 1px solid #eeede4;

          @include respond-min(1100px) {
            border-right: 1px solid #eeede4;
            height: auto;
            margin-top: 35px;
            width: 0;
            margin-bottom: 35px;
            margin-left: 0;
            border-bottom: 0;
          }
        }

        .locations-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          text-align: center;

          .locations-card-text {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond-min(1100px) {
              margin-top: 11%;
            }

            .locations-icon {
              background-image: url("../images/locationsPageAssets/Fill 591 + Fill 592 + Fill 593@2x.png");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              background-position-y: top;
              width: 50px;
              height: 50px;
              margin-top: 20px;
            }

            p {
              color: $paragraph-font-gray;
              font-family: proxima-nova, sans-serif;
              font-size: 16px;
              font-weight: 500;

              @include respond-max($breakpoint-xxsm) {
                font-size: 15px;
              }
            }

            h1 {
              color: $title-font-blue;
              font-family: proxima-nova, sans-serif;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: -0.33px;
              padding: 0 8px;

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                font-size: 18px;
              }

              @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                font-size: 22px;
              }
            }

            .locations-contact-container {
              display: flex;
              justify-content: center;

              @include respond-range($breakpoint-xxsm, 420px) {
                flex-direction: column;
                align-items: center;
              }

              p:first-child {
                @include respond-range($breakpoint-xxsm, 420px) {
                  margin-bottom: -17px;
                }
              }

              .locations-vertical-divider {
                display: none;

                @include respond-min(421px) {
                  display: inline;
                  margin: 17px 5px 0px 5px;
                  color: $paragraph-font-gray;
                }
              }
            }

            .locations-title {
            }

            .locations-address {
            }

            .locations-contact-numbers {
            }
          }

          a {
            text-decoration: none;
          }

          .locations-directions-btn {
            width: 130px;
            background-color: $red-button;
            height: 42px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $header-font;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 25px;
            cursor: pointer;
          }

          .locations-directions-btn:hover {
            background-color: $red-button-hover;
          }
        }
      }

      .join-us-coming-soon {
        width: 90%;
        color: $title-font-blue;
        font-family: $header-font;
        font-size: 37px;
        font-weight: 300;
        text-align: center;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-top: 12%;
          font-size: 24px;
        }

        @include respond-range($breakpoint-xsm, 768px) {
          margin-bottom: 5%;
          font-size: 24px;
        }

        @include respond-min($breakpoint-xsm) {
          margin-top: 5%;
        }

        @include respond-min(1100px) {
          text-align: center;
        }
      }
    }
  }
}
