﻿@import "../modules/constants";
@import "../modules/mixins";

.visibility-location-container {
  height: 100vh;
  background-color: #faf9f0;
}

/* custom style to semantic dimmer */
.ui.inverted.dimmer.light-tan-dimmer {
  background-color: rgba(250, 249, 240, 0.85);
}

.contact-us-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  align-content: space-between;
  justify-content: center;

  .contact-us-body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 750px;
    background-color: $light-tan;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      height: auto;
      max-height: none;
    }

    .contact-us-sections-container {
      width: 95%;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      bottom: 5%;
      max-height: 1024px;
      max-width: 1400px;
      min-height: 700px;

      @include respond-range($breakpoint-xxsm, 471px) {
        width: 100%;
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        height: auto;
        justify-content: space-evenly;
        max-height: none;
        bottom: 26px;
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        bottom: 4px;
      }

      @include respond-range($breakpoint-xsm, 650px) {
        bottom: 15px;
      }

      @include respond-range($breakpoint-md, 1080px) {
        bottom: 6%;
      }

      .contact-us-header {
        display: flex;
        width: 100%;
        padding: 20px;

        .heading-contactus {
          width: 70%;
          color: $title-font-blue;
          font-family: $header-font;
          font-size: 54px;
          font-weight: 600;
          line-height: 88px;

          @include respond-range($breakpoint-xxsm, 498px) {
            font-size: 250%;
          }
        }

        .close-contact {
          width: 30%;
          color: #d0d0d0;
          font-family: $header-font;
          font-size: 13px;
          font-weight: bold;
          line-height: 21px;
          text-align: right;
          cursor: pointer;
          align-self: center;
        }
      }

      .contact-form-body {
        width: 100%;
        padding: 20px;

        .write-us {
          color: $title-font-blue;
          font-size: 30px;
          font-weight: 500;
          letter-spacing: -0.41px;
          line-height: 36px;
          font-family: $paragraph-font;

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            margin-bottom: 50px;
          }
        }

        .thank-you-us-txt {
          color: $title-font-blue;
          font-size: 30px;
          font-weight: 500;
          letter-spacing: -0.41px;
          line-height: 36px;
          font-family: $paragraph-font;

          @include respond-range($breakpoint-xxsm, 585px) {
            font-size: 25px;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            /*margin-bottom: 50px;*/
          }
        }

        .close-submission-form {
          height: 61px;
          width: 203px;
          background-color: $red-button;
          box-shadow: 6px 6px 0 0 $lighter-gray;
          text-align: center;
          padding: 20px;
          color: #ffffff;
          font-family: $header-font;
          font-size: 13px;
          font-weight: bold;
          line-height: 20px;
          cursor: pointer;
        }

        .close-submission-form:hover {
          background-color: $red-button-hover;
        }

        .message-text-reach {
          color: $paragraph-font-gray;
          font-family: $paragraph-font;
          font-size: 18px;
          font-weight: 500;
          line-height: 29px;
          padding-top: 1rem;
          padding-bottom: 2rem;
        }

        .msg-body-card {
          display: flex;

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            display: block;
          }

          .submission-img {
            background-image: url("../images/commonAssets/Group 3@2x.png");
            height: 274px;
            width: 146px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-position-y: top;

            @include respond-range($breakpoint-xxsm, 585px) {
              margin: 0 auto;
              margin-top: 30px;
            }
          }
        }

        .form-sections {
          display: flex;
          width: 100%;

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            height: auto;
            max-height: none;
            display: block;
          }

          .inputs-fields {
            width: 70%;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 100%;
            }

            label {
              color: #969696;
              font-size: 18px;
              font-family: $paragraph-font;
            }

            .fields-main {
              display: flex;
              margin-top: 30px;
              width: 100%;

              label {
                color: #969696;
                font-size: 18px;
                font-family: $paragraph-font;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                display: block;
                margin-top: 0;
              }
            }

            .fields-main-select {
              width: 90%;
              margin-top: 30px;

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                width: 100%;
                margin-top: 0;
              }

              label {
                color: #969696;
                font-size: 18px;
                font-family: $paragraph-font;
              }
            }

            .fields-main-text-area {
              margin-top: 30px;
              width: 90%;

              label {
                color: #969696;
                font-size: 18px;
                font-family: $paragraph-font;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                width: 100%;
              }

              @include respond-range($breakpoint-xxsm, 599px) {
                margin-top: 0;
              }
            }

            .fields-main-send-button {
              display: flex;
              justify-content: flex-end;
              margin-top: 30px;
              width: 90%;

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                width: 100%;
                justify-content: center;
              }

              .contact-send {
                background-color: $red-button;
                box-shadow: 6px 6px 0 0 #f2f2f2;
                height: 61px;
                width: 203px;
                text-align: center;
                align-self: center;
                padding: 21px;
                color: $light-tan;
                font-family: $header-font;
                cursor: pointer;
                font-size: 13px;
                font-weight: bold;
              }

              .contact-send:hover {
                background-color: $red-button-hover;
              }
            }
          }

          .card-contacts {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;
            box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            height: 422px;
            margin-top: 30px;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 100%;
              height: 50%;
              margin-top: 60px;
            }

            @include respond-range($breakpoint-xxsm, 420px) {
              justify-content: space-between;
            }

            .contact-card-text {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;

              @include respond-min($breakpoint-md) {
                //height: 276px;
                width: 100%;
                //margin-bottom: 40px;
              }

              @include respond-range($breakpoint-xxsm, 420px) {
                height: 239px;
                justify-content: space-between;
              }

              .contact-icon {
                background-image: url("../images/commonAssets/commonIcons/contact-email-icon.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-position-y: top;
                width: 50px;
                height: 50px;
                margin-top: 40px;
              }

              .contact-title {
                color: $title-font-blue;
                font-family: $paragraph-font;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: -0.33px;
                padding: 0 8px;

                @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                  font-size: 18px;
                }

                @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                  font-size: 22px;
                }
              }

              .contact-infos {
                display: flex;
                width: 70%;
                min-width: 221px;

                @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                  width: 100%;
                }

                .nm {
                  width: 30%;
                  font-family: $paragraph-font;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 29px;
                  text-align: start;
                }

                .eml {
                  width: 70%;
                  font-family: $paragraph-font;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 29px;
                  text-align: start;
                  color: $red-button;
                  text-align: end;
                }

                .eml:hover {
                  color: $red-button-hover;
                }
              }
            }

            .social-icn {
              display: flex;
              justify-content: center;
              margin-bottom: 55px;
              margin-left: 20px;

              .icon-insta {
                background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-instagram-11@2x.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-position-y: top;
                width: 19px;
                height: 19px;
                margin-top: 40px;
                margin-right: 25px;
              }

              .icon-fb {
                background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-facebook-5@2x.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-position-y: top;
                width: 19px;
                height: 19px;
                margin-top: 40px;
                margin-right: 25px;
              }

              .icon-tw {
                background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-twitter-5@2x.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-position-y: top;
                width: 19px;
                height: 19px;
                margin-top: 40px;
                margin-right: 25px;
              }

              .icon-likedin {
                background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-linkedin-5@2x.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-position-y: top;
                width: 19px;
                height: 19px;
                margin-top: 40px;
                margin-right: 25px;
              }
            }
          }
        }
      }

      .hidden.transition {
        visibility: visible;
      }

      .animating.out.visible.transition {
        animation: none;
      }
    }
  }

  #submission-body-container {
    @include respond-max($breakpoint-md) {
      height: 100vh;
    }

    #submission-sections-container {
      min-height: 568px;

      @include respond-range($breakpoint-xxsm, 1080px) {
        bottom: 0;
      }

      #submission-header-container {
        display: flex;
        width: 90%;
        padding: 0;
        justify-content: space-between;

        #submission-header {
          width: auto;

          @include respond-range($breakpoint-xxsm, 585px) {
            font-size: 30px;
          }
        }

        #submission-close-contact {
          width: auto;
        }
      }

      #submission-content-container {
        width: 90%;
        padding: 0;

        #submission-body-card {
          @include respond-range($breakpoint-xxsm, 585px) {
            display: block;
          }

          @include respond-range(586px, $breakpoint-md) {
            display: flex;
          }
        }
      }
    }
  }
}
