﻿@import "../modules/constants";
@import "../modules/mixins";

.footer-container {
  display: flex;
  justify-content: center;
  height: auto;
  background: #ffffff;
  width: 100%;

  .footer-content-container {
    width: 90%;
    margin-top: 8%;
    margin-bottom: 8%;
    height: auto;
    max-width: 1440px;

    @include respond-range($breakpoint-xxsm, 1065px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @include respond-min($breakpoint-xsm) {
      margin-top: 10%;
      margin-bottom: 3%;
    }

    @include respond-min(1100px) {
      margin-top: 4%;
    }

    @include respond-min(1900px) {
      margin-top: 1%;
    }

    .truly-different-title {
      color: $title-font-blue;
      font-family: $paragraph-font;
      font-size: 30px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 25px;

      @include respond-range($breakpoint-xxsm, 500px) {
        font-size: 20px;
      }
    }

    .call-nav-items-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      @include respond-range($breakpoint-xxsm, 1065px) {
        margin-bottom: 5%;
        flex-direction: column;
        width: 95%;
        align-items: center;
      }

      .call-today-text-container {
        @include respond-min($breakpoint-sm) {
          display: flex;
        }

        .call-today-text {
          color: $title-font-blue;
          font-family: $paragraph-font;
          font-size: 18px;
          font-weight: bold;
          line-height: 19px;

          @include respond-range($breakpoint-xxsm, 768px) {
            font-size: 13px;
          }
        }

        #call-today-smaller {
          font-weight: 600;
        }

        #call-today-phone-number {
          @include respond-min($breakpoint-sm) {
            margin-left: 10px;
          }
        }
      }

      .footer-nav-items-container {
        float: right;
        display: flex;
        width: 540px;
        justify-content: space-between;

        @include respond-max(1065px) {
          width: 95%;
          justify-content: space-around;
          flex-wrap: wrap;
        }

        @include respond-max($breakpoint-xsm) {
          justify-content: center;
        }

        .footer-nav-item {
          color: $title-font-blue;
          font-family: $header-font;
          font-size: 13px;
          font-weight: 600;
          text-decoration: underline;
          cursor: pointer;

          @include respond-max(1065px) {
            margin: 5px 5px;
          }
        }

        .footer-nav-item:hover {
          //font-weight: bold;
          text-shadow: 0 0 0.01px #34406b, 0 0 0.01px #34406b;
        }

        .footer-nav-item.contact:hover {
          text-shadow: 0 0 0.01px $red-button, 0 0 0.01px $red-button;
        }
      }
    }

    .social-media-links-container {
      float: right;
      display: flex;
      width: 150px;
      justify-content: space-between;
      margin-top: 5px;

      @include respond-range($breakpoint-xxsm, 500px) {
        width: 60%;
      }

      .sml-link-instagram {
        background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-instagram-11@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
        width: 20px;
        height: 20px;
      }

      .sml-link-facebook {
        background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-facebook-5@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
        width: 20px;
        height: 20px;
      }

      .sml-link-twitter {
        background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-twitter-5@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
        width: 20px;
        height: 20px;
      }

      .sml-link-linkedin {
        background-image: url("../images/commonAssets/socialMediaIcons/iconmonstr-linkedin-5@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: top;
        width: 20px;
        height: 20px;
      }
    }

    .privacy-copyright-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-end;
      flex-direction: row-reverse;

      @include respond-range($breakpoint-xxsm, 1065px) {
        flex-direction: column;
        align-items: center;
      }

      .privacy-terms-container {
        display: flex;
        margin-top: 5%;
        flex-direction: column;
        text-align: right;

        @include respond-range($breakpoint-xxsm, 1065px) {
          flex-direction: row;
          justify-content: space-between;
          width: 225px;
        }

        @include respond-range($breakpoint-xxsm, 500px) {
          div {
            font-size: 10px;
          }
        }

        div {
          color: $paragraph-font-gray;
          font-family: $paragraph-font;
          font-size: 12px;
          font-weight: 500;
          text-decoration: underline;
        }

        .privacy-policy-text {
        }

        .terms-conditions-text {
        }
      }

      .copyright-terms-container {
        display: flex;
        justify-content: space-between;
        margin-top: 5%;
      }

      .privacy-policy-text,
      .copyright-text,
      .terms-conditions-text {
        color: $paragraph-font-gray;
        font-family: $paragraph-font;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  #locations-footer-content-container {
    margin-top: 75px;

    @include respond-max($breakpoint-xsm) {
      margin-top: 10%;
    }
  }

  #privacy-footer-content-container {
    margin-top: 75px;
  }
}

.about-page {
  margin-top: -300px;

  @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
    margin-top: -27%;
  }
}
