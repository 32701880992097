﻿@import "../modules/constants";
@import "../modules/mixins";

.homepage-container {
  width: 100%;
  overflow: hidden;

  p {
    font-family: $paragraph-font;
    line-height: 29px;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 0 0;
    color: $paragraph-font-gray;
  }

  .paragraph-end-link:hover {
    color: $red-button-hover !important;
  }

  h1,
  h2,
  h3 {
    font-family: $header-font;
    margin: 0 0 0 0;
  }

  h1 {
    font-size: 66px;
    font-weight: 600;
    color: #ffffff;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 30px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 54px;
    font-weight: bold;
    color: $title-font-blue;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 25px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 35px;
    }
  }

  h3 {
    font-size: 22px;
  }

  a {
    text-decoration: none;
  }

  .navbar-hero-container {
    background-color: $light-tan;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      background-image: url("../images/homePageAssets/hero-image-sm@2x.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-position-y: top;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-xxl) {
      background-image: url("../images/homePageAssets/hero-image-md@2x.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-position-y: top;
      height: 81vw;
    }

    @include respond-min($breakpoint-xxl) {
      background-image: url("../images/homePageAssets/hero-image-lg-clean@2x.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-position-y: center;
      background-color: $light-tan;
      height: 79vw;
    }

    @include respond-range($breakpoint-xxsm, 600px) {
      height: auto;
    }

    .homepage-hero-container {
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-range($breakpoint-xxsm, 600px) {
        width: 100%;
        height: 131%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      @include respond-range($breakpoint-xxsm, 380px) {
        margin-top: 4%;
      }

      @include respond-range(380px, 600px) {
        margin-top: 6%;
      }

      @include respond-range($breakpoint-xxsm, 600px) {
        height: 55vw;
      }

      .hero-text-content-container {
        width: 1062px;
        max-width: 1129px;
        height: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          /*margin-bottom: 23%;*/
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
          width: 92.4%;
        }

        @include respond-range($breakpoint-sm, $breakpoint-sm) {
          margin-top: 4%;
        }

        @include respond-range($breakpoint-sm, $breakpoint-md) {
          margin-top: 5%;
        }

        @include respond-range($breakpoint-sm, 1150px) {
          width: 92.4%;
        }

        #hero-title-lighter {
          font-weight: 100;
        }

        h1 {
        }

        .hero-title-difference {
          font-weight: 600;
          margin-top: 0px;
          margin-bottom: 20px;
        }

        .hero-paragraph {
          color: #faf9f0;
          max-width: 530px;
          margin-bottom: 4%;

          @include respond-max(600px) {
            display: none;
          }
        }

        .hero-learn-more-button {
          @include respond-range($breakpoint-xxsm, 600px) {
            display: none;
          }

          @include respond-min($breakpoint-sm) {
            width: 176px;
            height: 55px;
          }

          @include respond-min($breakpoint-md) {
            width: 203px;
            height: 61px;
          }

          width: 156px;
          background-color: #f6490d;
          height: 45px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 6px 6px 0 0 #f2f2f2;
          font-family: $header-font;
          font-size: 13px;
          font-weight: bold;
          cursor: pointer;
        }

        .hero-learn-more-button:hover {
          background-color: $red-button-hover;
        }
      }
    }

    .hero-learn-more {
      @include respond-min(601px) {
        display: none;
      }

      margin-top: 8%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .hero-paragraph {
        color: $paragraph-font-gray;
        width: 94%;
        text-align: center;
        margin-bottom: 35px;
      }

      .hero-learn-more-button {
        width: 156px;
        background-color: #f6490d;
        height: 45px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 6px 6px 0 0 #f2f2f2;
        font-family: $header-font;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
      }

      .hero-learn-more-button:hover {
        background-color: $red-button-hover;
      }
    }
  }

  .navbar-hero-container.base {
  }

  .navbar-hero-container.TX {
  }

  .navbar-hero-container.IL {
    background-image: url("../images/homePageAssets/hero-image-md@2x.jpg");

    .homepage-hero-container {
      height: 90%;

      @include respond-max($breakpoint-sm) {
        height: 75%;
      }

      @include respond-range($breakpoint-xxsm, 600px) {
        height: 55vw;
      }

      .hero-text-content-container {
        @include respond-max(600px) {
          padding-top: 15%;
        }

        @include respond-range($breakpoint-sm, $breakpoint-sm) {
          margin-top: 0;
        }

        @include respond-range($breakpoint-sm, $breakpoint-md) {
          margin-top: 0;
        }

        #hero-title-lighter {
          font-size: 60px;

          @include respond-max(1041px) {
            font-size: 5vw;
          }
        }
      }
    }
  }

  .navbar-hero-container.FL {
  }

  .navbar-hero-container.UT {
  }

  .homepage-why-truly-container {
    width: 100%;
    height: auto;
    max-height: 905px;
    min-height: 700px;
    display: flex;
    justify-content: center;
    background-color: $light-tan;

    @include respond-max(600px) {
      max-height: none;
    }

    .why-truly-content-container {
      width: 100%;
      margin-top: -5%;

      @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
        width: 92.4%;
        margin-top: 0;
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        margin-top: 100px;
      }

      @include respond-range($breakpoint-xsm, 600px) {
        margin-top: 15%;
      }

      .visibility-why-truly-text-container {
        display: flex;
        justify-content: center;

        .why-truly-text-container {
          margin-bottom: 52px;

          @include respond-range($breakpoint-xsm, $breakpoint-xsm) {
            margin-left: 0;
          }

          @include respond-min($breakpoint-xsm) {
            width: 1062px;
            display: flex;
            flex-direction: column;
          }

          @include respond-range($breakpoint-sm, 1150px) {
            width: 92.4%;
          }

          h2 {
            font-weight: 100;

            @include respond-range($breakpoint-xxsm, 768px) {
              margin-bottom: 0px;
            }
          }

          .why-truly-bigger-title {
            font-weight: bolder;
            letter-spacing: 4px;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              letter-spacing: 1px;
            }

            @include respond-range($breakpoint-sm, $breakpoint-sm) {
              /*margin-top: -12%;*/
            }
          }

          .why-truly-paragraphs {
            margin-top: -20px;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              margin-top: 5%;
              text-align: left;
            }

            @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-top: 30px;
            }

            p {
              width: 75%;
              max-width: 746px;
              float: right;
              color: $paragraph-font-gray;

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                width: 100%;
              }

              @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                width: 90%;
                float: none;
              }

              @include respond-range(769px, 900px) {
                width: 69%;
              }
            }

            .paragraph-end-link {
              color: #f6490d;
              text-decoration: underline;
            }
          }
        }

        .why-truly-text-container.IL {
          margin-top: 69px;

          @include respond-max($breakpoint-sm) {
            margin-top: 30px;
          }

          @include respond-max(600px) {
            margin-top: 0;
          }
        }
      }

      .description-cards-container {
        width: 100%;
        height: auto;
        display: flex !important;
        justify-content: center;

        .why-truly-description-cards {
          width: 1072px;
          height: auto;
          justify-content: center;

          @include respond-min($breakpoint-xxsm) {
            margin-bottom: 26%;
          }

          @include respond-min($breakpoint-xl) {
            margin-bottom: 20%;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
            width: 356px;
          }

          @include respond-range($breakpoint-sm, 1150px) {
            width: 710px;
          }

          .description-card-slide-container {
            display: flex !important;
            justify-content: center;
            width: auto;
            outline: none;

            .truly-description-card {
              box-sizing: border-box;
              height: 441px;
              width: 354px;
              border: 1px solid #f2f2f2;
              background-color: #ffffff;
              margin-top: 15px;
              margin-bottom: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                width: 90%;
                margin-top: 5%;
              }

              @include respond-max(1150px) {
                height: 431px;
                width: 344px;
              }

              .description-card-content-container {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: auto;
                margin-top: 45px;

                .why-truly-icon {
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  background-position-y: top;
                  width: 50px;
                  height: 50px;
                }

                #why-truly-tech-icon {
                  background-image: url("../images/homePageAssets/Fill 442@2x.png");
                }

                #why-truly-settlement-icon {
                  background-image: url("../images/homePageAssets/Fill 381 + Fill 382@2x.png");
                }

                #why-truly-friendly-icon {
                  background-image: url("../images/homePageAssets/Group@2x (1).png");
                }

                .description-card-title {
                  color: $title-font-blue;
                  font-family: proxima-nova, sans-serif;
                  font-size: 24px;
                  font-weight: bold;
                  letter-spacing: -0.33px;
                  line-height: 29px;
                  margin-top: 30px;

                  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                    font-size: 18px;
                  }

                  @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                    font-size: 22px;
                  }
                }

                .description-card-paragraph {
                  color: $paragraph-font-gray;
                  font-size: 16px;
                  width: 300px;
                  margin-top: 15px;

                  @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                    width: 93%;
                  }

                  @include respond-range($breakpoint-md, 1065px) {
                    width: 92%;
                  }
                }
              }

              .why-truly-read-more-button {
                width: 130px;
                background-color: $light-tan;
                height: 42px;
                color: #d1d0c7;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $header-font;
                font-size: 13px;
                font-weight: bold;
                margin-bottom: 30px;
              }
            }

            .truly-description-card:hover {
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

              .why-truly-read-more-button {
                width: 130px;
                background-color: #f6490d;
                height: 42px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $header-font;
                font-size: 13px;
                font-weight: bold;
                margin-bottom: 30px;
              }

              .why-truly-read-more-button:hover {
                background-color: $red-button-hover;
                cursor: pointer;
              }
            }
          }

          ul {
            position: relative;

            li {
              margin: 0;
            }

            button {
              width: 10px;
              height: 10px;
              border-radius: 8px;
              background-color: #e6e4d8;
            }

            button:before {
              content: none;
            }

            .slick-active {
              button {
                background-color: $red-button;
              }
            }
          }
        }

        .why-truly-carousel-container {
          width: 100%;
          display: flex;
          justify-content: center;

          .truly-description-card {
            box-sizing: border-box;
            height: auto;
            width: 354px;
            border: 1px solid #f2f2f2;
            background-color: #ffffff;
            margin-top: 15px;
            display: flex !important;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              width: 90%;
              margin-top: 5%;
            }

            @include respond-range($breakpoint-xsm, $breakpoint-md) {
              width: 436px;
              height: 441px;
            }

            @include respond-min(993px) {
              height: 441px;
            }

            .description-card-content-container {
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              height: auto;
              margin-top: 55px;

              @include respond-min(993px) {
                height: 300px;
                margin-top: 65px;
                justify-content: space-around;
              }

              .why-truly-icon {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-position-y: top;
                width: 50px;
                height: 50px;
              }

              #why-truly-tech-icon {
                background-image: url("../images/homePageAssets/Fill 442@2x.png");
              }

              #why-truly-settlement-icon {
                background-image: url("../images/homePageAssets/Fill 381 + Fill 382@2x.png");
              }

              #why-truly-friendly-icon {
                background-image: url("../images/homePageAssets/Group@2x (1).png");
              }

              .description-card-title {
                color: $title-font-blue;
                font-family: proxima-nova, sans-serif;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: -0.33px;
                line-height: 29px;
                margin-top: 30px;

                @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                  font-size: 18px;
                }

                @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                  font-size: 22px;
                }
              }

              .description-card-paragraph {
                color: $paragraph-font-gray;
                font-size: 16px;
                width: 300px;
                margin-top: 15px;
                font-family: $paragraph-font;

                @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                  width: 75%;
                }
              }
            }

            .why-truly-read-more-button {
              width: 130px;
              background-color: $red-button;
              height: 42px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: $header-font;
              font-size: 13px;
              font-weight: bold;
              margin-bottom: 56px;
            }

            .why-truly-read-more-button:hover {
              cursor: pointer;
              background-color: $red-button-hover;
            }
          }

          ul {
            position: relative;
            /*bottom: 360px;
							right: 115px;*/
            li {
              margin: 0;
            }

            button {
              width: 10px;
              height: 10px;
              border-radius: 8px;
              background-color: #e6e4d8;
            }

            button:before {
              content: none;
            }

            .slick-active {
              button {
                background-color: $red-button;
              }
            }
          }
        }
      }
    }
  }

  .homepage-visibility-technology-container {
    height: 69vw;
    max-height: 923px;

    @include respond-max($breakpoint-md) {
      height: auto;
      max-height: none;
    }

    .homepage-technology-container {
      width: 100%;
      height: 100%;
      margin-top: 0px;
      display: flex;
      justify-content: center;
      background: linear-gradient(180deg, #faf9f0 0%, #ffffff 100%);

      @include respond-max($breakpoint-md) {
        height: auto;
        background: #ffffff;
      }

      .tech-content-container {
        width: 100%;

        @include respond-max($breakpoint-md) {
          display: flex;
          flex-direction: column;
          height: auto;
        }

        .tech-text-background-container {
          display: flex;
          justify-content: center;
          background: linear-gradient(to right, #f2f2f2 60%, #faf9f0 40%);
          width: 100%;
          position: absolute;
          z-index: 0;
          height: 57vw;
          max-height: 771px;

          @include respond-max($breakpoint-md) {
            width: 100%;
            position: relative;
            height: 718px;
            display: flex;
            align-items: flex-start;
            background: #f2f2f2;
          }

          .tech-text-content {
            width: 1062px;
            margin-top: 5%;

            @include respond-range($breakpoint-sm, $breakpoint-md) {
              margin-top: 34px;
            }

            @include respond-range($breakpoint-xxsm, 1150px) {
              width: 92.4%;
            }

            @include respond-min(2100px) {
              margin-top: 105px;
            }

            h2 {
              font-weight: 100;
            }

            .tech-bigger-title {
              font-weight: bolder;
              margin-top: 0px;
              letter-spacing: 4px;
              margin-bottom: 10px;
            }

            .tech-text-paragraphs {
              width: 33%;

              @include respond-range($breakpoint-md, 1065px) {
                width: 29%;
              }

              @include respond-range($breakpoint-xsm, $breakpoint-md) {
                width: 90%;
              }

              @include respond-max($breakpoint-xsm) {
                width: auto;
              }

              ol {
                list-style: none;
                counter-reset: my-awesome-counter;
              }

              ol li {
                counter-increment: my-awesome-counter;
                color: $paragraph-font-gray;
                font-family: $paragraph-font;
                line-height: 29px;
                font-size: 18px;
                font-weight: 500;
              }

              ol li::before {
                content: counter(my-awesome-counter) ". ";
                color: $paragraph-font-gray;
                font-family: $paragraph-font;
                line-height: 29px;
                font-size: 18px;
                font-weight: 500;
              }

              p {
                color: $paragraph-font-gray;
              }

              .paragraph-end-link {
                color: #f6490d;
                text-decoration: underline;
              }
            }
          }
        }

        .fade,
        .up,
        .visible,
        .transition {
          width: 100%;

          @include respond-max($breakpoint-sm) {
            height: 28%;
            order: -1;
          }

          @include respond-min($breakpoint-sm) {
            height: 100%;
          }
        }

        .tech-transition-container {
          @include respond-max($breakpoint-md) {
            order: -1;
            height: auto;
          }

          .tech-red-block {
            width: 47.5%;
            height: 50px;
            position: relative;
            z-index: 1;
            background-color: red;
            top: 56px;
            left: 53%;

            @include respond-min(3400px) {
              left: 55%;
            }

            @include respond-max($breakpoint-md) {
              display: none;
            }
          }

          .tech-container-column-right {
            float: right;
            background-color: white;
            width: 54%;
            position: relative;
            z-index: 2;
            height: 100%;
            top: 19px;
            left: 1%;
            background-image: url("../images/homePageAssets/innovative-technology-md.jpg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            background-position-y: top;

            @include respond-max($breakpoint-xsm) {
              background-image: url("../images/homePageAssets/innovative-technology-sm.jpg");
            }

            @include respond-max($breakpoint-md) {
              width: 100%;
              height: 52vw;
              top: 0px;
              left: 0px;
              background-position: bottom;
              background-position-y: center;
              background-size: cover;
            }

            @include respond-min(2225px) {
              background-size: cover;
              background-position-y: center;
            }

            .tech-img-container {
            }
          }

          .tech-carousel-container {
            width: 100%;
            display: flex;
            justify-content: center;

            @include respond-max($breakpoint-md) {
              display: none;
            }

            .carousel-content-container {
              position: relative;
              width: 370px;
              height: 436px;
              background-color: #383d46;
              box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.2);
              color: white;
              z-index: 3;
              bottom: 35vw;

              @include respond-max($breakpoint-sm) {
                top: 0px;
              }

              @include respond-min(1465px) {
                bottom: 512px;
              }

              div:first-child {
                outline: none;
              }

              .prev-arrow-container {
                position: relative;
                top: 348px;
                left: 0px;
                width: 184px;
                height: 88px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #3d434d;

                .prev-arrow-img {
                  background-image: url("../images/commonAssets/commonIcons/icon-left-arrow.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  width: 25px;
                  height: 25px;
                }
              }

              .next-arrow-container {
                position: relative;
                top: 13px;
                left: 184px;
                width: 186px;
                height: 88px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #3d434d;
                font-size: 35px;

                .next-arrow-img {
                  background-image: url("../images/commonAssets/commonIcons/icon-right-arrow.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  width: 25px;
                  height: 25px;
                }
              }

              .prev-arrow-container:hover,
              .next-arrow-container:hover {
                background-color: #5c636e;
                cursor: pointer;
              }

              .slick-list {
                margin-left: 40px;
                position: relative;
                top: -10px;
              }

              ul {
                position: relative;
                bottom: 393px;
                right: 115px;

                li {
                  margin: 0;
                }

                button {
                  width: 10px;
                  height: 10px;
                  border-radius: 8px;
                  background-color: #5c636e;
                }

                button:before {
                  content: none;
                }

                .slick-active {
                  button {
                    background-color: $red-button;
                  }
                }
              }

              .slider-content-title {
                color: #ffffff;
                font-family: $paragraph-font;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: -0.33px;
                line-height: 29px;
                margin-bottom: 15px;
              }

              .slider-content-paragraph {
                width: 315px;
                color: #c5c5c5;
                font-family: $paragraph-font;
                font-size: 16px;
                font-weight: 500;
                line-height: 29px;
              }

              .slider-content-link {
                height: 29px;
                width: 50px;
                color: $red-button;
                font-family: $paragraph-font;
                font-size: 16px;
                font-weight: bold;
                line-height: 29px;
                text-decoration: underline;
                cursor: pointer;
              }

              .slider-content-link:hover {
                color: $red-button-hover;
              }
            }
          }
        }

        .tech-carousel-container-small-screen {
          width: 100%;
          display: flex;
          justify-content: center;

          @include respond-min(993px) {
            display: none;
          }

          .carousel-content-container {
            position: relative;
            width: 370px;
            height: 436px;
            background-color: #383d46;
            box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.2);
            color: white;
            z-index: 3;

            @include respond-max($breakpoint-md) {
              top: -347px;
            }

            @include respond-min(2700px) {
              top: -625px;
            }

            @include respond-min(3000px) {
              top: -675px;
            }

            @include respond-min(3400px) {
              top: -725px;
            }

            div:first-child {
              outline: none;
            }

            .prev-arrow-container {
              position: relative;
              top: 348px;
              left: 0px;
              width: 184px;
              height: 88px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #3d434d;

              .prev-arrow-img {
                background-image: url("../images/commonAssets/commonIcons/icon-left-arrow.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 25px;
                height: 25px;
              }
            }

            .next-arrow-container {
              position: relative;
              top: 13px;
              left: 184px;
              width: 186px;
              height: 88px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #3d434d;
              font-size: 35px;

              .next-arrow-img {
                background-image: url("../images/commonAssets/commonIcons/icon-right-arrow.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 25px;
                height: 25px;
              }
            }

            .prev-arrow-container:hover,
            .next-arrow-container:hover {
              background-color: #5c636e;
              cursor: pointer;
            }

            .slick-list {
              margin-left: 40px;
              position: relative;
              top: -15px;
            }

            ul {
              position: relative;
              bottom: 398px;
              right: 115px;

              li {
                margin: 0;
              }

              button {
                width: 10px;
                height: 10px;
                border-radius: 8px;
                background-color: #5c636e;
              }

              button:before {
                content: none;
              }

              .slick-active {
                button {
                  background-color: $red-button;
                }
              }
            }

            .slider-content-title {
              color: #ffffff;
              font-family: $paragraph-font;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: -0.33px;
              line-height: 29px;
              margin-bottom: 15px;
            }

            .slider-content-paragraph {
              width: 310px;
              color: #c5c5c5;
              font-family: $paragraph-font;
              font-size: 16px;
              font-weight: 500;
              line-height: 29px;
            }

            .slider-content-link {
              height: 29px;
              width: 50px;
              color: $red-button;
              font-family: $paragraph-font;
              font-size: 16px;
              font-weight: bold;
              line-height: 29px;
              text-decoration: underline;
              cursor: pointer;
            }

            .slider-content-link:hover {
              color: $red-button-hover;
            }
          }
        }
      }
    }
  }

  .homepage-visibility-services-container {
    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      margin-top: -100px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      margin-top: -166px;
    }

    @include respond-range(769px, $breakpoint-md) {
      margin-top: -200px;
    }

    .homepage-services-container {
      width: 100%;
      height: auto;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        height: auto;
      }

      @include respond-min(993px) {
        margin-top: 275px;
      }

      .services-background-full-transition {
        background: linear-gradient(
          180deg,
          rgba(251, 251, 251, 0) 0%,
          #f5f5f5 100%
        );

        @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
          height: 715px;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          height: 693px;
        }

        @include respond-range($breakpoint-sm, $breakpoint-md) {
          height: 660px;
        }

        @include respond-min(993px) {
          height: 527px;
        }

        .services-text-content-container {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 0;

          .services-text-content {
            width: 92.4%;
            max-width: 1097px;
            height: 780px;
            background-color: #ffffff;
            position: relative;
            text-align: center;
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              height: 738px;
            }

            @include respond-range(481px, $breakpoint-sm) {
              height: 782px;
            }

            @include respond-range($breakpoint-md, $breakpoint-lg) {
              width: 92%;
            }

            .services-title-container {
              display: flex;
              flex-direction: column;

              .services-bigger-title {
                font-weight: bolder;
                margin-top: -75px;
                letter-spacing: 4px;

                @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                  margin-top: -160px;
                }
              }

              h2 {
                color: $title-font-blue;
                font-weight: 100;
              }
            }

            .services-paragraph-container {
              width: 90%;
              margin: 0 auto;
              margin-top: 30px;

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                margin-top: -40px;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                text-align: left;
                margin-top: -50%;
                width: 92.4%;
              }

              @include respond-range($breakpoint-xxsm, 325px) {
                margin-top: -59%;
              }
            }

            .services-description-container {
              display: flex;
              margin-top: 63px;
              justify-content: space-between;
              width: 100%;
              height: 337px;

              @include respond-max($breakpoint-md) {
                display: none;
              }

              @include respond-range(993px, 1155px) {
                margin-top: 38px;
              }

              .description-card-divider {
                border-left: 2px solid #f2f2f2;
                height: 420px;
              }

              .service-description-card {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 85px;
                outline: none;

                .service-description-title {
                  color: $title-font-blue;
                  font-family: $header-font;
                  font-size: 30px;
                  font-weight: bold;
                  letter-spacing: -0.41px;
                  margin-bottom: 50px;
                }

                .service-description-paragraph {
                  text-align: left;
                  width: 420px;
                  color: $paragraph-font-gray;
                }

                .paragraph-end-link {
                  margin-right: 366px;
                  color: $red-button;
                  text-decoration: underline;
                  cursor: pointer;
                }

                .paragraph-end-link:hover {
                  color: $red-button-hover;
                }
              }

              #description-card-right {
                /*width: 420px;*/
                height: 310px;
                padding-top: 85px;
              }
            }

            .services-carousel-button-container {
              margin-bottom: 30px;

              .services-carousel-container {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: -100px;

                @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                  margin-top: -123px;
                  margin-bottom: 45px;
                }

                @include respond-min(993px) {
                  display: none;
                }

                .services-carousel-content {
                  width: 383px;

                  @include respond-max(515px) {
                    width: 92%;
                  }

                  .service-description-card {
                    width: 420px;
                    padding-top: 85px;
                    outline: none;

                    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                      padding-top: 55px;
                    }

                    .service-description-title {
                      color: $title-font-blue;
                      font-family: $header-font;
                      font-size: 30px;
                      font-weight: bold;
                      letter-spacing: -0.41px;
                      margin-bottom: 50px;

                      @include respond-range(
                        $breakpoint-xxsm,
                        $breakpoint-xsm
                      ) {
                        font-size: 18px;
                      }

                      @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                        font-size: 20px;
                      }

                      @include respond-max($breakpoint-sm) {
                        margin-bottom: 30px;
                      }
                    }

                    .service-description-paragraph {
                      text-align: left;
                      color: $paragraph-font-gray;
                    }

                    .paragraph-end-link {
                      color: $red-button;
                      text-decoration: underline;
                      text-align: left;
                      cursor: pointer;
                    }

                    .paragraph-end-link:hover {
                      color: $red-button-hover;
                    }
                  }

                  ul {
                    position: relative;

                    li {
                      margin: 0;
                    }

                    button {
                      width: 10px;
                      height: 10px;
                      border-radius: 8px;
                      background-color: #e6e4d8;
                    }

                    button:before {
                      content: none;
                    }

                    .slick-active {
                      button {
                        background-color: $red-button;
                      }
                    }
                  }

                  @include respond-min(993px) {
                    display: none;
                  }
                }
              }

              .discover-btn-container {
                display: flex;
                justify-content: center;
                margin-top: 111px;
                /*margin-bottom: 30px;*/
                @include respond-max($breakpoint-md) {
                  margin-top: 0;
                }

                .services-discover-button {
                  width: 203px;
                  background-color: #f6490d;
                  height: 61px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  box-shadow: 6px 6px 0 0 #f2f2f2;
                  font-family: $header-font;
                  font-size: 13px;
                  font-weight: bold;
                }

                .services-discover-button:hover {
                  background-color: $red-button-hover;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .services-background-full-image {
        height: 750px;
        background-color: $darkest-blue;
        background-image: url("../images/homePageAssets/handshake-image@2x.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        background-position-y: bottom;

        @include respond-max($breakpoint-md) {
          height: 54vw;
          background-color: #383d46;
          background-size: contain;
          background-position: center;
          background-position-y: top;
          width: 100%;
        }

        @include respond-max($breakpoint-xsm) {
          background-image: url("../images/homePageAssets/handshake-image-sm@2x.jpg");
        }

        @include respond-range($breakpoint-xsm, $breakpoint-xxl) {
          background-image: url("../images/homePageAssets/handshake-image-md@2x.jpg");
        }

        @include respond-min($breakpoint-xxl) {
          background-image: url("../images/homePageAssets/handshake-image-lg@2x.jpg");
          height: 39vw;
          max-height: 1100px;
        }
      }
    }
  }
}
