﻿@import "../modules/constants";
@import "../modules/mixins";

.about-page-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  align-content: space-between;
  justify-content: center;

  p {
    font-family: $paragraph-font;
    line-height: 29px;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 0 0;
    color: $paragraph-font-gray;
  }

  h1,
  h2,
  h3 {
    color: $title-font-blue;
    font-family: $header-font;
    margin: 0 0 0 0;
  }

  h1 {
    font-size: 66px;
    font-weight: 600;
    color: #ffffff;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 30px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 54px;
    font-weight: bold;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 25px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 35px;
    }
  }

  h3 {
    font-size: 22px;
  }

  .navbar-hero-container {
    display: flex;
    flex-direction: column;
    background-image: url("../images/aboutPageAssets/about-hero@3x-min.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-position-y: top;
    background-color: #ffffff;

    @include respond-range($breakpoint-xxsm, 600px) {
      background-size: cover;
      height: 75vw;
    }

    @include respond-range(600px, $breakpoint-sm) {
      background-size: cover;
      height: 65vw;
    }

    @include respond-min($breakpoint-sm) {
      background-size: contain;
      height: 55vw;
    }

    @include respond-min($breakpoint-xxl) {
      background-size: cover;
      background-position: center;
      background-position-y: center;
      max-height: 1056px;
    }

    .about-page-hero-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      height: auto;
      width: 100%;

      .hero-text-container {
        display: flex;
        flex-direction: column;
        width: 70%;
        min-width: 650px;
        max-width: 1129px;
        margin-bottom: 14%;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-bottom: 20%;
        }

        @include respond-range($breakpoint-xsm, $breakpoint-sm) {
          margin-bottom: 10%;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
          width: 90%;
          min-width: 0;
        }

        @include respond-min($breakpoint-xxl) {
          margin-bottom: 269px;
        }
      }
    }
  }

  .our-mission-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    margin-top: 88px;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      margin-top: 60px;
    }

    .our-mission-content {
      width: 70%;
      max-width: 1129px;
      min-width: 650px;

      @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
        width: 90%;
        min-width: 0;
        height: auto;
      }

      .mission-header-container {
        max-width: 856px;
        height: 303px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          /*height: 55%;*/
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
          height: auto;
        }

        .mission-main-header-container {
          display: flex;
          width: 324px;
          justify-content: space-between;

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            width: 154px;
            margin-bottom: 20px;
          }

          @include respond-range($breakpoint-xsm, $breakpoint-sm) {
            width: 210px;
            margin-bottom: 25px;
          }

          .h2-lighter {
            font-weight: 300;
          }
        }

        .mission-red-block-underline {
          width: 100px;
          height: 5px;
          background-color: red;
          margin-top: -10px;

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            width: 47px;
            margin-top: 0;
            margin-bottom: 20px;
          }

          @include respond-range($breakpoint-xsm, $breakpoint-sm) {
            width: 65px;
            margin-top: 0;
            margin-bottom: 25px;
          }
        }

        .visibility-paragraph-container {
          min-height: 132px;

          @include respond-max($breakpoint-xsm) {
            min-height: 230px;
          }

          .mission-large-paragraph {
            font-size: 31px;
            color: #5c636e;
            font-weight: 500;
            line-height: 44px;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              font-size: 22px;
              line-height: 29px;
            }
          }
        }
      }

      .mission-paragraph-container {
        margin-top: 80px;
        width: 100%;
        height: 203px;
        /*display: flex;*/
        display: none;
        justify-content: flex-end;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-top: 20px;
        }

        @include respond-range($breakpoint-xsm, $breakpoint-sm) {
          margin-top: 40px;
        }

        .mission-paragraph-content {
          width: 70%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          min-width: 650px;

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            align-items: flex-start;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
            width: 100%;
            min-width: 0;
            flex-direction: column;
          }

          @include respond-range($breakpoint-xsm, $breakpoint-sm) {
            align-items: center;
          }

          .mission-paragraph {
            width: 350px;
            font-size: 20px;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              width: 95%;
            }

            @include respond-range($breakpoint-xsm, $breakpoint-sm) {
              width: 90%;
            }
          }

          p:last-child {
            margin-left: 20px;

            @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
              margin-left: 0;
              margin-top: 20px;
            }

            @include respond-range($breakpoint-xsm, $breakpoint-sm) {
              margin-top: 20px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .office-photos-section-container {
    width: 100%;
    height: 40vw;
    max-height: 601px;
    margin-top: 125px;
    display: flex;
    justify-content: center;

    @include respond-range($breakpoint-sm, 1100px) {
      margin-bottom: 150px;
    }

    @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
      margin-top: 88px;
      height: 50vw;
    }

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      height: 239px;
      margin-top: -7%;
    }

    .office-photos-content {
      width: 100%;
      height: auto;

      .tan-background-block {
        width: 72%;
        height: 33vw;
        max-height: 525px;
        background-color: $light-tan;

        @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
          display: none;
        }
      }

      .visibility-photo-container {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        position: relative;
        bottom: 30vw;

        @include respond-max($breakpoint-sm) {
          bottom: 0;
        }

        @include respond-min($breakpoint-xl) {
          bottom: 478px;
        }

        .office-photos-container {
          width: 79%;
          display: flex;
          justify-content: center;
          height: 589px;
          max-width: 900px;

          @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
            width: 100%;
            margin-left: 0;
            height: 50vw;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            height: 239px;
          }

          .our-mission-handshake {
            background-image: url("../images/aboutPageAssets/about-our-mission@2x-min.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-position-y: top;
            height: 589px;
            width: 79%;
            min-width: 198px;
            margin-left: 15%;

            @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
              background-size: cover;
              background-position-y: top;
              height: 100%;
              width: 100%;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .core-values-container {
    width: 100%;
    height: auto;
    background-color: $darkest-blue;
    display: flex;
    justify-content: center;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      position: relative;
    }

    .core-values-content {
      height: auto;
      width: 70%;
      max-width: 1129px;
      min-width: 650px;
      margin-bottom: 282px;

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        margin-top: 35px;
        width: 100%;
        min-width: 0;
      }

      @include respond-range($breakpoint-xsm, $breakpoint-sm) {
        width: 90%;
        min-width: 0;
        margin-bottom: 220px;
      }

      @include respond-range($breakpoint-sm, $breakpoint-lg) {
        margin-bottom: 17%;
      }

      .core-value-row {
        width: 100%;
        height: 25vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 2px solid #414752;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          justify-content: center;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
          height: auto;
        }

        @include respond-range(769px, $breakpoint-lg) {
          height: 340px;
        }

        @include respond-min($breakpoint-sm) {
          max-height: 347px;
        }

        .values-header-container {
          display: flex;
          flex-direction: column;

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            text-align: center;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            margin-top: 4%;
            margin-bottom: 10%;
          }

          @include respond-range($breakpoint-xsm, $breakpoint-sm) {
            margin: 6% 0;
          }

          h2 {
            color: #ffffff;
          }

          .lighter-header {
            font-weight: 300;
          }
        }

        .visibility-value-container {
          width: 95%;
          height: auto;

          @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
            margin: 54px 0;
          }

          .value-content {
            width: 100%;
            height: auto;
            display: flex !important;
            justify-content: space-between;

            @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
              flex-direction: column;
              align-items: center;
            }

            h3 {
              color: #ffffff;
              font-size: 40px;
              font-weight: 500;
              width: 50%;
              margin-right: 15px;

              @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                font-size: 24px;
                margin-bottom: 10px;
                width: 70%;
                margin-right: 0;
                text-align: left;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                margin-top: 15px;
                width: 90%;
              }
            }

            .value-paragraph {
              color: $paragraph-font-darker-gray;
              font-size: 20px;
              width: 523px;

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                width: 90%;
                margin-left: 0;
                margin-bottom: 15px;
              }

              @include respond-range(481px, $breakpoint-sm) {
                width: 70%;
                margin-left: 0;
              }

              @include respond-range(769px, $breakpoint-lg) {
                width: 57%;
              }
            }
          }
        }
      }

      div:last-child {
        border-bottom: none;
      }
    }
  }

  .about-team-photo {
    background-image: url("../images/aboutPageAssets/about-team-photo@3x-min.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-position-y: top;
    height: 28vw;
    max-height: 438px;
    width: 70%;
    max-width: 1129px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    bottom: 14vw;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      background-size: cover;
      height: 100vw;
      width: 100%;
      bottom: 57vw;
    }

    @include respond-range($breakpoint-xsm, 767px) {
      bottom: 25vw;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-lg) {
      background-size: cover;
      height: 38vw;
      width: 100%;
    }

    @include respond-min($breakpoint-xl) {
      bottom: 224px;
    }
  }

  .our-team-container {
    width: 100%;
    height: auto;
    background: linear-gradient(
      180deg,
      #faf9f0 0%,
      rgba(250, 249, 240, 0) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    bottom: 28vw;
    z-index: 0;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
    }

    @include respond-range($breakpoint-sm, $breakpoint-lg) {
      bottom: 38vw;
    }

    @include respond-min($breakpoint-xl) {
      bottom: 448px;
    }

    .red-block-container {
      width: 100%;
      height: 15vw;

      @include respond-range($breakpoint-sm, $breakpoint-lg) {
        height: 26vw;
      }

      @include respond-min($breakpoint-xl) {
        height: 240px;
      }

      .red-block {
        width: 40%;
        background-color: $red-button;
        float: right;
        height: 100%;
      }
    }

    .our-team-content {
      height: auto;
      width: 70%;
      max-width: 1129px;
      min-width: 650px;
      margin-top: 129px;

      .employee-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 7.5em;
        margin-top: 100px;

        @include respond-max(700px) {
          display: none;
        }

        @include respond-max(1350px) {
          grid-template-columns: 1fr 1fr;
        }

        .employee-card-container {
          display: flex !important;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 286px;
          height: 354px;
          border: 2px solid #f2f2f2;
          margin: 0 auto;

          .img-container {
            width: 275px;
            height: 270px;
            background-color: #f2f2f2;
            margin-top: 3px;
            display: flex;
            justify-content: center;
            align-items: center;

            .employee-image {
              width: 100%;
              height: 100%;
            }
          }

          .employee-info-container {
            width: 100%;
            height: 81px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .employee-name {
              color: $title-font-blue;
              font-family: $paragraph-font;
              font-size: 22px;
              font-weight: bold;
              letter-spacing: -0.3px;
              line-height: 26px;
              margin-top: 8px;
              margin-bottom: 2px;
            }

            .employee-position {
              color: #a3a4a9;
              font-family: $paragraph-font;
              font-size: 14px;
              font-weight: 600;
              line-height: 29px;
            }
          }
        }
        .were-hiring-container-wrapper {
          align-self: center;
        }

        .were-hiring-container {
          display: flex !important;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          width: 286px;
          height: 354px;
          background-color: #f6490d;
          border: 2px solid #f2f2f2;
          margin: 0 auto;
          margin-bottom: 100px;

          h3 {
            font-size: 46px;
            font-weight: 300;
            color: #ffffff;
            width: 190px;
            margin-top: 15px;
          }

          .contact-us-btn {
            background-color: #ffffff;
            width: 130px;
            height: 42px;
            text-align: center;
            letter-spacing: -0.25px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $red-button;
            font-family: $header-font;
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 30px;
          }
        }
      }

      @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
        margin-top: 69px;
      }

      @include respond-range($breakpoint-xxsm, 700px) {
        min-width: 0;
      }

      @include respond-range($breakpoint-xxsm, 420px) {
        width: 300px;
      }

      .our-team-header-container {
        display: flex;
        width: 272px;
        justify-content: space-between;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          width: 128px;
          margin: 0 auto;
        }

        @include respond-range($breakpoint-xsm, $breakpoint-sm) {
          width: 176px;
        }

        .header-lighter {
          font-weight: 300;
        }
      }

      .hide-slider {
        display: none;
        @include respond-max(700px) {
          display: block;
        }
      }

      .visibility-employee-cards-container {
        width: 100%;
        height: auto;
        margin-top: 99px;
        position: relative;
        z-index: 3;
        .employee-card-container {
          display: flex !important;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 286px;
          height: 354px;
          border: 2px solid #f2f2f2;
          margin: 0 auto;

          .img-container {
            width: 275px;
            height: 270px;
            background-color: #f2f2f2;
            margin-top: 3px;
            display: flex;
            justify-content: center;
            align-items: center;

            .employee-image {
              width: 100%;
              height: 100%;
            }
          }

          .employee-info-container {
            width: 100%;
            height: 81px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .employee-name {
              color: $title-font-blue;
              font-family: $paragraph-font;
              font-size: 22px;
              font-weight: bold;
              letter-spacing: -0.3px;
              line-height: 26px;
              margin-top: 8px;
              margin-bottom: 2px;
            }

            .employee-position {
              color: #a3a4a9;
              font-family: $paragraph-font;
              font-size: 14px;
              font-weight: 600;
              line-height: 29px;
            }
          }
        }
        .were-hiring-container-wrapper {
          align-self: center;
        }

        .were-hiring-container {
          display: flex !important;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          width: 286px;
          height: 354px;
          background-color: #f6490d;
          border: 2px solid #f2f2f2;
          margin: 0 auto;

          h3 {
            font-size: 46px;
            font-weight: 300;
            color: #ffffff;
            width: 190px;
            margin-top: 15px;
          }

          .contact-us-btn {
            background-color: #ffffff;
            width: 130px;
            height: 42px;
            text-align: center;
            letter-spacing: -0.25px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $red-button;
            font-family: $header-font;
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 30px;
          }
        }
        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-top: 33px;
        }

        div:focus {
          outline: none;
          border: none;
        }

        .slick-dots {
          /*bottom: 0;*/
          @include respond-range($breakpoint-xxsm, 700px) {
            /*display: none !important;*/
          }
        }

        .slick-prev,
        .slick-next {
          background: $red-button;
          /* for now, with just 5 employees, we're not displaying these arrows for the single card view */
          /*display: block;*/
          display: none;
          content: none;
          border-radius: 20px;

          @include respond-range($breakpoint-xxsm, 420px) {
            position: relative;
          }

          @include respond-min(700px) {
            display: none;
          }
        }

        .slick-prev:hover,
        .slick-next:hover {
          background: $red-button-hover;
        }

        .slick-prev:before,
        .slick-next:before {
          content: none;
        }

        .slick-next {
          background-image: url("../images/commonAssets/commonIcons/icon-right-arrow.png") !important;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          background-position: 5px !important;
          background-position-y: top !important;

          @include respond-range($breakpoint-xxsm, 420px) {
            right: -176px;
            bottom: -26px;
          }
        }

        .slick-prev {
          background-image: url("../images/commonAssets/commonIcons/icon-left-arrow.png") !important;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          background-position: 2px !important;
          background-position-y: top !important;

          @include respond-range($breakpoint-xxsm, 420px) {
            top: 400px;
            left: 92px;
          }
        }

        ul {
          position: relative;
          /*bottom: 360px;
							right: 115px;*/
          li {
            margin: 0;
          }

          button {
            width: 10px;
            height: 10px;
            border-radius: 8px;
            background-color: #e6e4d8;
          }

          button:before {
            content: none;
          }

          .slick-active {
            button {
              background-color: $red-button;
            }
          }
        }

        .centered {
          justify-content: center;
        }
      }
    }
  }
}
