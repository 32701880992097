﻿@import "../modules/constants";
@import "../modules/mixins";
.signup-bg {
  height: 100vh;
  background-color: #faf9f0;

  .signup-page {
    background-color: #faf9f0;
    padding-bottom: 30px;

    #signup-page {
      background-color: white;
      margin: 40px auto 40px auto;
      width: 60% !important;
      transition: 0.4s ease all;
      border: 1px solid white;
      box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.2);

      @include respond-max($breakpoint-sm) {
        width: 90% !important;
      }

      .content-container {
        width: 90%;
        margin: 0 auto;
        height: 100%;
        margin-top: 20px;
        margin-bottom: 45px;
        background-color: white;

        .closing-btn {
          float: right;
          color: #d0d0d0;
          font-family: $header-font;
          font-size: 13px;
          font-weight: bold;
          /*margin-top: 20px;*/
          cursor: pointer;
        }

        .header {
          height: auto;
          width: auto;
          color: #34406b;
          font-family: $paragraph-font;
          font-size: 30px;
          font-weight: 500;
          letter-spacing: -0.41px;
          line-height: 36px;
          padding-top: 60px;

          @include respond-max-width-height(420px, 782px) {
            padding-top: 2%;
          }
        }

        .description-paragraph {
          color: #999999;
          font-family: $paragraph-font;
          font-size: 18px;
          font-weight: 600;
          line-height: 29px;
          margin-top: 20px;
        }

        .troole-graphic {
          background-image: url("../images/commonAssets/Group 3@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 222px;
          height: 355px;
          margin: 0 auto;

          @include respond-max-width-height(420px, 782px) {
            height: 34vh;
          }
        }

        .form-container {
          .inputs-fields {
            width: 100%;

            .fields-main {
              display: flex;
              justify-content: space-between;
              margin-top: 30px;
              width: 100%;

              @include respond-max($breakpoint-sm) {
                flex-direction: column;
                margin-top: 0;
              }

              label {
                color: #969696;
                font-size: 18px;
                font-family: $paragraph-font;
              }

              .custom-input {
                width: 47% !important;
                margin-bottom: 0 !important;
                margin-right: 0 !important;

                @include respond-max($breakpoint-sm) {
                  width: 100% !important;
                  margin-top: 30px !important;
                }
              }

              .full-width {
                width: 100% !important;

                @include respond-max($breakpoint-sm) {
                  margin-top: 30px !important;
                }
              }

              .quarter-width {
                width: 23% !important;

                @include respond-max($breakpoint-sm) {
                  width: 100% !important;
                }
              }

              .account-manager-field-label {
                font-size: 12px;
                top: -3px;
              }
            }
          }
        }

        .sign-up-btn {
          height: 62px;
          width: 203px;
          background-color: $red-button;
          box-shadow: 6px 6px 0 0 #f2f2f2;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          margin: 0 auto;
          margin-top: 50px;
          margin-bottom: 30px;
          cursor: pointer;
        }

        .confirm-close-btn {
          @include respond-max-width-height(420px, 575px) {
            margin-bottom: 0;
            margin-top: 24px;
          }
        }

        .sign-up-btn:hover {
          background-color: $red-button-hover;
        }

        .small-paragraph {
          /*margin-bottom: 45px;*/
          color: #999999;
          font-family: $paragraph-font;
          font-size: 13px;
          text-align: center;
        }
      }
      .scrolling.dimmable.dimmed > .dimmer {
        overflow: overlay !important;
      }
    }
  }
}

/* Deals with form looking misaligned when screen height creates overflow on the form
		Although "overlay" appears as an invalid value here...it works for webkit (safari) and blink (chrome/opera)
		based browsers. It isn't supported by Firefox, IE, etc...but in these browsers where "overlay" isn't supported,
		the overflow value will default to the Semantic UI code and set "overflow: auto" and all is happy.
	*/
