﻿@import "../modules/constants";
@import "../modules/mixins";

.privacy-page-container {
	/*display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;*/
	p {
		color: $paragraph-font-gray;
		font-family: $paragraph-font;
		font-size: 15px;
		font-weight: 500;
		line-height: 25px;
	}

	.bottom-border {
		border-bottom: 1px solid #D4D4D4;
		display: flex;
		justify-content: center;
		width: 100%;
		height: auto;

		.privacy-page-content {
			width: 85%;
			max-width: 724px;
			margin-top: 170px;
			margin-bottom: 60px;

			@include respond-max($breakpoint-md) {
				margin-top: 17%;
			}

			.privacy-header {
				color: $title-font-blue;
				font-family: $header-font;
				font-size: 54px;
				font-weight: 300;
				line-height: 62px;
				margin-bottom: 18px;
			}

			.privacy-subheader {
				font-weight: bold;
				margin-bottom: 0;
			}

			#main-subheader {
				margin-bottom: 35px;
			}

			.section-header {
				color: $title-font-blue;
				font-weight: 600;
				margin-bottom: 0;
			}

			.privacy-paragraph {
				margin-bottom: 30px;
			}

			.smaller-margin {
				margin-bottom: -10px;
			}

			.info-use-list {
				list-style: none;
				margin-bottom: 30px;

				li {
					color: $paragraph-font-gray;
					font-family: $paragraph-font;
					font-size: 15px;
					font-weight: 500;
					line-height: 25px;
				}

				li:before {
					content: "•"; /* Insert content that looks like bullets */
					/*padding-right: 8px;*/
					color: $paragraph-font-gray;
					display: inline-block;
					width: 2em;
					margin-left: -2em;
				}
				/*.list-item {
					display: inline-block;
				}*/
			}
		}
	}
}