﻿@import "../modules/constants";
@import "../modules/mixins";

.resources-page-container {
  .navbar-hero-container {
    background-image: url("../images/resourcesPageAssets/resources-hero@2x-min.jpg");
    height: 32vw;
    max-height: 614px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      height: 172px;
    }

    .resources-page-hero-container {
      @include respond-max($breakpoint-md) {
        justify-content: flex-start;
      }
    }

    .hero-text-container {
      @include respond-min(769px) {
        margin-bottom: 0 !important;
      }

      @include respond-max($breakpoint-xsm) {
        margin-bottom: 0 !important;
      }
    }
  }
}

.resources-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 120px;

  @include respond-max($breakpoint-md) {
    margin-top: 12%;
  }

  .width-container {
    width: 75%;
    max-width: 1074px;
    min-width: 650px;
    /*@include respond-range(993px, $breakpoint-lg) {
            width: 87.4%;
        }*/
    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      width: 90%;
      min-width: 0;
    }
  }

  .width-container.mob-full {
    @include respond-max(992px) {
      width: 100%;
    }
  }

  .dropdown-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 695px;

    @include respond-max(445px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .dropdown {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .down-arrow {
        background-image: url("../images/commonAssets/commonIcons/dropdown-arrow-expand@2x.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height: 16px;
        margin-left: 10px;
      }

      .title-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .title {
        font-size: 34px;
        font-family: $header-font;
        color: $title-font-blue;
        border-bottom: 4px solid $red-button;
        line-height: 62px;

        @include respond-max($breakpoint-sm) {
          font-size: 34px;
          line-height: 48px;
        }

        @include respond-max(520px) {
          font-size: 28px;
          line-height: 36px;
        }
      }

      .division.title {
        font-weight: 900;
        min-width: 134px;

        @include respond-max($breakpoint-sm) {
          min-width: 104px;
        }

        @include respond-max(510px) {
          min-width: 86px;
        }
      }

      .resource.title {
        font-weight: 100;
        min-width: 230px;

        @include respond-max($breakpoint-sm) {
          min-width: 230px;
        }

        @include respond-max(510px) {
          min-width: 205px;
        }
      }

      .dropdown-options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px 0px;
        transition: 0.3s ease all;
        position: absolute;
        width: 100%;
        box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.1);
        background: white;
        top: 69px;

        @include respond-max($breakpoint-sm) {
          top: 55px;
        }

        @include respond-max(510px) {
          top: 40px;
        }

        .option {
          width: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          padding: 8px 0;
          border-bottom: 1px solid #f5f5f5;
          cursor: pointer;
          color: #9b9b9b;

          .option-txt {
            width: 75%;
            height: 100%;
            display: flex;
            align-items: center;
            font-family: $header-font;
            font-size: 14px;
            font-weight: 600;
            padding-left: 20px;
            /*min-width: 120px;*/
          }
        }

        .option:last-child {
          .option-txt {
            border: none;
          }
        }

        .option.active {
          color: $red-button;
        }

        .option:hover {
          background-color: #f8f8f8;
        }
      }

      .dropdown-options.open {
        height: auto;
        z-index: 2;
        opacity: 1;
      }

      .dropdown-options.closed {
        visibility: hidden;
        height: 0px;
        opacity: 0;
      }

      #div-options {
        // max-height: 115px;
      }
    }

    .dropdown:last-child {
      margin-left: 5%;

      @include respond-max(445px) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  .resource-docs-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 300px;
    margin-top: 60px;

    .resource-section {
      width: 100%;
      margin-bottom: 60px;

      .title {
        font-size: 24px;
        color: $title-font-blue;
        margin-bottom: 30px;
        font-family: $header-font;
        font-weight: 600;

        @include respond-max(992px) {
          margin-bottom: 0;
          padding-left: 5%;
        }

        .doc-count {
          display: none;
          font-size: 20px;

          @include respond-max(992px) {
            display: inline;
          }
        }
      }

      .sub-title {
        font-size: 16px;
        color: $title-font-blue;
        margin-top: 20px;
        padding-left: 5%;
      }

      #no-docs-desktop {
        padding-left: 0;
        @include respond-max(992px) {
          display: none;
        }
      }

      #no-docs-mobile {
        @include respond-min(993px) {
          display: none;
        }
      }
    }

    .card-container {
      width: 100%;
      justify-content: flex-start;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(235px, 235px));
      grid-gap: 1.5rem;

      @include respond-max(992px) {
        display: none;
      }
    }

    .indiana-scroll {
      width: 100%;
      /*padding: 0 30px;*/
      position: relative;
      /*right: 30px;*/
      cursor: pointer;
      height: 320px;
      display: flex;
      right: 0;
      padding: 0;
      padding-left: 5%;

      @include respond-min(997px) {
        display: none;
      }
    }

    .card-container::-webkit-scrollbar {
      display: none;
    }

    .card-container::-ms-scrollbar {
      display: none;
    }

    .card-container::-moz-scrollbar {
      display: none;
    }

    .resource-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px 15px;
      border: 1px solid #f2f2f2;
      width: 235px;
      min-width: 235px;

      @include respond-max(992px) {
        margin-right: 30px;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .doc-icon {
        background-image: url("../images/commonAssets/commonIcons/icon-resourcedoc@2x.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 30px;
        height: 40px;
        margin-bottom: 20px;
      }

      .description {
        text-align: center;
        margin: 5px 0 15px 0;
        position: relative;
        height: 57px;
        font-family: $paragraph-font;
        color: #999999;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* for some reason this box orient property isn't being applied...leaving it here for now, but also adding style inline */
        -webkit-box-orient: vertical !important;
        overflow: hidden;
      }

      .doc-title {
        font-size: 20px;
        font-weight: bold;
        font-family: $paragraph-font;
        color: $title-font-blue;
        text-align: center;
        min-height: 60px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }

    .resource-card:hover {
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);

      .btn-tan {
        background-color: $red-button;
        color: #ffffff;
      }

      .btn-tan:hover {
        background-color: $red-button-hover;
      }
    }
  }
}
