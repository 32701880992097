﻿@import "../modules/constants";
@import "../modules/mixins";

.tech-page-container {
  width: 100%;
  /*overflow: hidden;*/
  display: flex;
  flex-direction: column;
  height: auto;
  align-content: space-between;
  justify-content: center;

  p {
    font-family: $paragraph-font;
    line-height: 29px;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 0 0;
    color: $paragraph-font-gray;
  }

  h1,
  h2,
  h3 {
    color: $title-font-blue;
    font-family: $header-font;
    margin: 0 0 0 0;
  }

  h1 {
    font-size: 66px;
    font-weight: 600;
    color: #ffffff;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 30px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 54px;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 25px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 35px;
    }
  }

  h3 {
    font-size: 22px;
  }

  .navbar-hero-container {
    display: flex;
    flex-direction: column;
    background-image: url("../images/techPageAssets/tech-hero@2x-min.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-position-y: top;
    background-color: $light-tan;

    @include respond-range($breakpoint-xxsm, 600px) {
      background-size: cover;
      height: 75vw;
    }

    @include respond-range(600px, $breakpoint-sm) {
      background-size: cover;
      height: 65vw;
    }

    @include respond-min($breakpoint-sm) {
      background-size: contain;
      height: 53vw;
    }

    @include respond-min($breakpoint-xxl) {
      background-size: cover;
      background-position: center;
      max-height: 1056px;
    }

    .tech-page-hero-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      height: auto;
      width: 100%;

      .hero-text-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 1400px;
        margin-bottom: 14%;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          width: 90%;
          min-width: 0;
        }

        @include respond-min($breakpoint-xxl) {
          margin-bottom: 269px;
        }

        h1 {
          color: #ffffff;
        }

        .title-lighter {
          font-weight: 300;
        }
      }
    }
  }

  .innovative-tech-container {
    width: 100%;
    background-color: $light-tan;
    display: flex;
    justify-content: center;

    .innovative-tech-content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      max-width: 1400px;
      margin-top: 55px;
      margin-bottom: 120px;

      @include respond-max($breakpoint-xsm) {
        width: 90%;
      }

      @include respond-max(900px) {
        flex-direction: column;
      }

      @include respond-min($breakpoint-xxl) {
        margin-top: 100px;
      }

      @include respond-max($breakpoint-sm) {
        margin-bottom: 15%;
      }

      .tech-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        position: relative;
        z-index: 1;
        width: 41vw;
        margin-left: 25px;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          width: 90%;
        }

        @include respond-max(900px) {
          width: 100%;
          margin-left: 0;
        }

        h2 {
          font-weight: 100;
        }

        .title-bolder {
          font-weight: bold;

          @include respond-min($breakpoint-xxsm) {
            margin-bottom: 10px;
          }
        }

        p {
          @include respond-min($breakpoint-xxsm) {
            text-align: left;
          }

          @include respond-min($breakpoint-sm) {
            max-width: 650px;
          }
        }
      }

      .img-container {
        background-image: url("../images/techPageAssets/tech-troole@3x-min.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        max-height: 535px;
        max-width: 725px;
        min-width: 280px;
        min-height: 231px;

        @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
          width: 72vw;
          height: 60vw;
        }

        @include respond-min(900px) {
          height: 31vw;
          width: 41vw;
        }

        @include respond-max(900px) {
          height: 44vw;
          width: 60vw;
          margin: 0 auto;
        }
      }
    }
  }

  .integrated-platform-container {
    background: linear-gradient(180deg, #faf9f0 0%, #ffffff 100%);
    height: 50vw;
    max-height: 800px;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      height: auto;
      width: 100%;
      max-height: 940px;
      margin-bottom: 88px;
    }

    @include respond-range(993px, 1100px) {
      margin-bottom: 88px;
    }

    @include respond-max(900px) {
      margin-bottom: 0;
    }

    .integrated-platform-content {
      height: 100%;
      max-width: 1750px;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        display: flex;
        flex-direction: column;
      }

      @include respond-min($breakpoint-xl) {
        margin: 0 auto;
      }

      .platform-text-background-container {
        background: linear-gradient(to right, #f2f2f2 70%, transparent 30%);
        position: relative;
        z-index: 0;
        height: 38vw;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond-min($breakpoint-xl) {
          max-height: 608px;
        }

        @include respond-range($breakpoint-xxsm, 400px) {
          height: auto;
        }

        @include respond-range(400px, 600px) {
          height: auto;
        }

        @include respond-range(600px, $breakpoint-md) {
          height: auto;
        }

        @include respond-range(900px, 1100px) {
          height: auto;
        }
        // keep this for now...in case we want to change the breakpoint of when this section stacks
        /*@include respond-max($breakpoint-sm) {
					background: none;
				}*/
        @include respond-max(900px) {
          background: none;
        }

        .content {
          display: flex;
          width: 80%;
          max-width: 1400px;
          justify-content: space-between;
          // keep this for now...in case we want to change the breakpoint of when this section stacks
          /*@include respond-max($breakpoint-sm) {
						flex-direction: column;
						align-items: center;
						width: 100%;
					}*/
          @include respond-max(900px) {
            flex-direction: column;
            align-items: center;
            width: 100%;
          }

          .text-content-container {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;
            // keep this for now...in case we want to change the breakpoint of when this section stacks
            /*@include respond-max($breakpoint-sm) {
							width: 100%;
							background: #F2F2F2;
						}*/
            @include respond-max(900px) {
              width: 100%;
              background: #f2f2f2;
            }

            .text-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              // keep this for now...in case we want to change the breakpoint of when this section stacks
              /*@include respond-max($breakpoint-sm) {
								width: 80%;
								margin-top: 20px;
								margin-bottom: 20px;
							}*/
              @include respond-max(900px) {
                width: 80%;
                margin-top: 20px;
                margin-bottom: 20px;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                width: 90%;
              }

              h2 {
                font-weight: 100;
              }

              .title-bolder {
                font-weight: bold;
                margin-bottom: 10px;
              }

              p {
                width: 88%;

                @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                  width: 97%;
                }

                @include respond-range($breakpoint-sm, $breakpoint-md) {
                  width: 97%;
                }
              }
            }
          }

          .visibility-img-container {
            width: 82vw;
            max-width: 669px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 340px;

            @include respond-max($breakpoint-sm) {
              min-width: 320px;
            }

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 100%;
            }

            .img-container {
              background-image: url("../images/techPageAssets/tech-platform-graphic@2x.png");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 100%;
              height: 50vw;
              max-height: 730px;
              min-height: 430px;
              // keep this for now...in case we want to change the breakpoint of when this section stacks
              /*@include respond-max($breakpoint-sm) {
								background-image: url("../images/techPageAssets/tech-platform-horizontal-graphic@2x.png");
								min-height: 320px;
							}*/
              @include respond-max(900px) {
                background-image: url("../images/techPageAssets/tech-platform-horizontal-graphic@2x.png");
                min-height: 320px;
              }
            }
          }
        }
      }
    }
  }

  .infrastructure-container {
    height: 50vw;
    max-height: 820px;
    overflow-y: hidden;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      height: auto;
      width: 100%;
      max-height: 985px;
    }

    @include respond-max(1100px) {
      margin-bottom: 88px;
    }

    @include respond-max(900px) {
      margin-bottom: 0;
    }

    @include respond-max(600px) {
      /*margin-bottom: 32%;*/
    }

    .infrastructure-content {
      height: 100%;
      max-width: 1750px;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        display: flex;
        flex-direction: column-reverse;
      }

      @include respond-min($breakpoint-xl) {
        margin: 0 auto;
      }

      .background-container {
        background: linear-gradient(to left, #f2f2f2 90%, transparent 10%);
        position: relative;
        z-index: 0;
        height: 38vw;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond-range(900px, 1100px) {
          height: 44vw;
          height: auto;
        }

        @include respond-min($breakpoint-xl) {
          max-height: 608px;
        }

        @include respond-max($breakpoint-md) {
          height: auto;
        }
        /*@include respond-max($breakpoint-sm) {
					background: none;
				}*/
        @include respond-max(900px) {
          background: none;
        }

        .content {
          display: flex;
          width: 80%;
          max-width: 1400px;
          justify-content: space-between;
          align-items: center;
          /*@include respond-max($breakpoint-sm) {
						flex-direction: column-reverse;
						width: 100%;
					}*/
          @include respond-max(900px) {
            flex-direction: column-reverse;
            width: 100%;
          }

          .visibility-img-container {
            width: 80vw;
            max-width: 778px;
            position: relative;
            right: 6%;
            min-width: 288px;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              bottom: 0;
              width: 100%;
            }
            /*@include respond-max($breakpoint-sm) {
							right: 0;
						}*/
            @include respond-max(900px) {
              right: 0;
              width: 95%;
              margin-top: 25px;
              margin-bottom: 25px;
            }

            .img-container {
              background-image: url("../images/techPageAssets/tech-infrastructure-graphic@2x (1).png");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 100%;
              height: 35vw;
              max-height: 528px;
              min-height: 205px;
              /*@include respond-max($breakpoint-sm) {
								background-position: top;
							}*/
              @include respond-max(900px) {
                background-position: top;
              }
            }
          }

          .text-container {
            width: 40%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 20px;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 60%;
              bottom: 0;
              justify-content: center;
            }

            @include respond-range($breakpoint-xxsm, 400px) {
              height: auto;
            }

            @include respond-range(400px, 600px) {
              height: auto;
            }
            /*@include respond-max($breakpoint-sm) {
							width: 100%;
							background: #F2F2F2;
						}*/
            /*@include respond-max($breakpoint-sm) {
							margin-bottom: 0;
						}*/
            @include respond-max(900px) {
              width: 100%;
              background: #f2f2f2;
              margin-bottom: 0;
            }

            .text-content {
              width: 100%;

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                margin-right: 0;
                margin-left: 0;
              }

              @include respond-range($breakpoint-xxsm, 600px) {
                margin: 46px 0;
              }

              @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                margin-left: 0;
              }

              @include respond-range($breakpoint-sm, $breakpoint-md) {
                margin-left: 0;
              }

              @include respond-min(1750px) {
                margin-left: 0;
              }
              /*@include respond-max($breakpoint-sm) {
								width: 80%;
								margin-top: 20px;
								margin-bottom: 20px;
							}*/
              @include respond-max(900px) {
                width: 80%;
                margin-top: 20px;
                margin-bottom: 20px;
              }

              @include respond-max($breakpoint-xsm) {
                width: 90%;
              }

              h2 {
                font-weight: 100;
              }

              .title-bolder {
                font-weight: bold;
                margin-bottom: 10px;
              }

              p {
              }
            }
          }
        }
      }
    }
  }

  .supportive-tech-container {
    height: 50vw;
    max-height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      height: auto;
      max-height: none;
    }

    @include respond-max(900px) {
      height: 680px;
    }

    .supportive-tech-content {
      max-width: 1750px;
      width: 100%;
      height: 100%;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        display: flex;
        flex-direction: column;
      }

      @include respond-min($breakpoint-xl) {
        margin: 0 auto;
      }

      .tech-text-background-container {
        background: linear-gradient(to right, $lighter-gray 90%, #ffffff 10%);
        position: relative;
        z-index: 0;
        height: 36vw;
        max-height: 518px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond-range($breakpoint-xxsm, 1180px) {
          height: auto;
        }

        @include respond-max(900px) {
          background: none;
          align-items: flex-start;
        }

        .content {
          display: flex;
          width: 80%;
          max-width: 1400px;
          justify-content: flex-start;
          align-items: center;

          @include respond-max(900px) {
            /*display: grid;
						grid-template-rows: 4% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
						grid-template-columns: 1fr 1fr;*/
            flex-direction: column;
            width: 100%;
          }

          .tech-text-container {
            width: 33%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond-range($breakpoint-xxsm, 400px) {
              height: auto;
            }

            @include respond-range(400px, 600px) {
              height: auto;
            }

            @include respond-range(993px, 1180px) {
              height: 73%;
            }

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              justify-content: center;
              align-items: center;
              bottom: 0;
            }

            @include respond-max(900px) {
              /*grid-column: 1 / 3;
							grid-row: 1 / 4;
							align-self: flex-start;
							padding-bottom: 24%;
							padding-top: 30px;*/
              width: auto;
              background: #f2f2f2;
            }

            .text-content {
              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                width: 90%;
                margin-left: 0;
                margin-right: 0;
                margin-top: 0;
              }

              @include respond-min(900px) {
                width: 100%;
              }

              @include respond-max(900px) {
                width: 80%;
                margin-top: 20px;
                margin-bottom: 40px;
              }

              @include respond-max($breakpoint-xsm) {
                width: 90%;
              }

              .header-container {
                h2 {
                  font-weight: 100;
                }

                .title-bolder {
                  font-weight: bold;
                  margin-bottom: 10px;
                }
              }

              p {
                width: 80%;

                @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                  width: 100%;
                }

                @include respond-max(1300px) {
                  width: 95%;
                }
              }
            }
          }

          .img-container {
            width: 14vw;
            max-width: 248px;
            height: 24vw;
            max-height: 688px;
            position: relative;
            top: 170px;
            background-image: url("../images/techPageAssets/tech-tools-graphic@2x.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @include respond-max(900px) {
              /*margin: 0 auto;
							grid-row: 5;
							grid-column: 1;
							height: 48vw;
							width: 27vw;
							min-height: 296px;
							min-width: 166px;*/
              display: none;
            }
          }

          .tech-card-container {
            /*height: 483px;
						width: 483px;*/
            height: 435px;
            width: 435px;
            border: 1px solid #f2f2f2;
            background-color: #ffffff;
            box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);
            position: relative;
            /*right: 7%;*/
            bottom: 80px;
            min-width: 320px;

            @include respond-range($breakpoint-xxsm, 1480px) {
              /*height: 32vw;
							width: 32vw;*/
            }

            @include respond-range(320px, 1180px) {
              bottom: 3rem;
              /*height: auto;*/
            }

            @include respond-max($breakpoint-md) {
              /*height: auto;*/
            }

            @include respond-max(900px) {
              /*right: 0;*/
              bottom: 20px;
              /*z-index: 100000;
							grid-column: 2 / 3;
							grid-row: 5;
							align-self: flex-start;*/
              min-width: 250px;
              /*width: 100%;*/
            }

            @include respond-min(901px) {
              left: 20px;
            }

            @include respond-max(435px) {
              width: 100%;
            }

            .tech-card-flex-display {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;

              .tech-card-content {
                width: 76%;
                height: auto;
                margin-top: 10%;
                margin-bottom: 5%;

                .tech-tools-icon {
                  background-image: url("../images/techPageAssets/icon-tools@2x.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  background-position-y: top;
                  height: 61px;
                  width: 67px;
                }

                h3 {
                  margin-top: 37px;
                  margin-bottom: 22px;

                  @include respond-max(1480px) {
                    width: 100%;
                  }

                  @include respond-range($breakpoint-xxsm, 395px) {
                    font-size: 20px;
                  }
                }

                ul {
                  padding-left: 20px;

                  li {
                    font-family: $paragraph-font;
                    font-size: 16px;
                    color: #5c636e;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sign-up-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .sign-up-content {
      width: 80%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 125px 0;

      .title-bolder {
        font-weight: bold;
      }

      h2 {
        font-weight: 100;
      }

      .btn {
        margin-top: 50px;
      }
    }
  }
}
