﻿@import "../modules/constants";
@import "../modules/mixins";

.services-page-container,
.resources-page-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
  align-content: space-between;
  justify-content: center;

  p {
    font-family: $paragraph-font;
    line-height: 29px;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 0 0;
    color: $paragraph-font-gray;
  }

  h1,
  h2,
  h3 {
    color: $title-font-blue;
    font-family: $header-font;
    margin: 0 0 0 0;
  }

  h1 {
    font-size: 66px;
    font-weight: 600;
    color: #ffffff;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 30px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 54px;
    font-weight: bold;

    @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
      font-size: 25px;
    }

    @include respond-range($breakpoint-xsm, $breakpoint-sm) {
      font-size: 35px;
    }
  }

  h3 {
    font-size: 22px;
  }

  .navbar-hero-container {
    display: flex;
    flex-direction: column;
    background-image: url("../images/servicesPageAssets/services-hero@3x-min.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-position-y: top;
    background-color: #ffffff;
    background-color: $light-tan;

    @include respond-range($breakpoint-xxsm, 600px) {
      background-size: cover;
      height: 75vw;
    }

    @include respond-range(600px, $breakpoint-md) {
      background-size: cover;
      height: 65vw;
    }

    @include respond-min($breakpoint-sm) {
      background-size: contain;
      height: 55vw;
    }

    @include respond-min($breakpoint-xxl) {
      background-size: cover;
      background-position: center;
      background-position-y: center;
      max-height: 996px;
    }

    .services-page-hero-container,
    .resources-page-hero-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      height: auto;
      width: 100%;
      /*@include respond-min($breakpoint-xxsm) {
				margin-top: 12%;
			}

			@include respond-min($breakpoint-xsm) {
				margin-top: 18%;
			}

			@include respond-min($breakpoint-sm) {
				margin-top: 11%;
			}

			@include respond-min($breakpoint-xxl) {
				margin-top: 288px;
			}*/
      .hero-text-container {
        display: flex;
        flex-direction: column;
        width: 75%;
        min-width: 650px;
        max-width: 1074px;
        margin-bottom: 14%;

        @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
          margin-bottom: 20%;
        }

        @include respond-range($breakpoint-xsm, $breakpoint-sm) {
          margin-bottom: 10%;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          width: 90%;
          min-width: 0;
        }

        @include respond-min($breakpoint-xxl) {
          margin-bottom: 269px;
        }

        h1 {
        }

        .title-lighter {
          font-weight: 300;
        }
      }
    }
  }

  .services-content-main {
    position: relative;

    @include respond-range($breakpoint-xxsm, $breakpoint-md) {
      position: static;
    }

    .our-offers-container {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      background-color: #ffffff;
      /*margin-top: 88px;*/
      background: linear-gradient(180deg, $light-tan 0%, #ffffff 100%);

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        /*margin-top: 36px;
				padding: 15px;*/
      }

      @include respond-range(993px, $breakpoint-lg) {
        justify-content: flex-end;
      }

      .our-offers-content {
        width: 75%;
        max-width: 1074px;
        min-width: 650px;
        padding-bottom: 160px;
        margin-top: 73px;

        @include respond-range(993px, $breakpoint-lg) {
          width: 87.4%;
          padding-bottom: 32vw;
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          /*width: 100%;
					min-width: 0;*/
        }

        @include respond-range($breakpoint-xxsm, $breakpoint-md) {
          width: 90%;
          min-width: 0;
          padding-bottom: 10vw;
        }

        .offers-header-container {
          // max-width: 856px;
          height: 123px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            height: 58px;
          }

          @include respond-range($breakpoint-xsm, $breakpoint-sm) {
            height: 80px;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            /*height: 85px;*/
          }

          .offers-main-header-container {
            display: flex;
            width: 400px;
            justify-content: space-between;

            @include respond-range($breakpoint-xsm, $breakpoint-sm) {
              // width: 210px;
            }

            h2 {
            }

            .h2-lighter {
              font-weight: 300;
            }
          }

          .offers-red-block-underline {
            width: 100px;
            height: 5px;
            background-color: red;
            margin-top: -10px;

            @include respond-range($breakpoint-xsm, $breakpoint-sm) {
              width: 65px;
            }
          }
        }

        .mission-paragraph-container {
          margin-top: 55px;
          width: 100%;
          display: flex;
          min-height: 200px;
          /* justify-content: flex-end; */
          @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
            margin-top: 24px;
          }

          @include respond-range($breakpoint-xsm, $breakpoint-sm) {
            margin-top: 30px;
          }

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            display: block;
          }

          .visibility-paragraph-container {
            /*min-height: 132px;
						padding-right: 40px;*/
            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              padding: 0;
            }

            @include respond-range(993px, $breakpoint-lg) {
              width: 447rem;
            }

            @include respond-min(1760px) {
              min-height: 300px;
            }

            .offers-large-paragraph {
              font-size: 31px;
              color: #5c636e;
              font-weight: 500;
              line-height: 44px;
              width: 450px;

              @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                /*font-size: 18px;
								line-height: 29px;*/
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-xsm) {
                font-size: 22px;
                line-height: 29px;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                width: 100%;
              }

              @include respond-range($breakpoint-md, $breakpoint-lg) {
                width: auto;
              }

              @include respond-range(993px, $breakpoint-sml) {
                /*font-size: 18px;*/
              }
            }
          }
          /* doesn't seem to be used */
          .mission-paragraph-content {
            width: 70%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            min-width: 650px;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 100%;
              min-width: 0;
              flex-direction: column;
              align-items: center;
            }

            @include respond-range(993px, $breakpoint-lg) {
              min-width: 0;
            }

            .mission-paragraph {
              width: 350px;
              font-size: 20px;

              @include respond-range($breakpoint-xsm, $breakpoint-md) {
                width: 90%;
              }
            }

            p:last-child {
              margin-left: 20px;

              @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                margin-top: 20px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .title-insurance-section-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 70vw;
      min-height: 945px;
      max-height: 1008px;
      background-color: $light-tan;

      @include respond-max($breakpoint-md) {
        max-height: none;
        height: auto;
      }

      .title-insurance-content {
        width: 100%;
        max-width: 1440px;
        height: inherit;

        @include respond-max($breakpoint-md) {
          display: flex;
          flex-direction: column;
        }

        .our-insurance-container {
          width: 100%;
          height: 35vw;
          max-height: 504px;
          display: flex;
          justify-content: flex-end;
          /*background: linear-gradient(180deg, #FAF9F0 0%, #FFFFFF 100%);*/
          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            display: block;
            height: auto;
          }

          .our-insurance-content {
            width: 95%;
            margin-top: -100px;
            display: flex;
            justify-content: flex-end;
            background-color: #f5f5f5;

            @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
              width: 100%;
              min-width: 0;
              margin-top: 0;
              justify-content: center;
            }

            @include respond-range(769px, $breakpoint-md) {
              width: 100%;
              margin-top: 0;
              justify-content: center;
            }

            .tryInsContent-01 {
              width: 92%;
              background-color: #f5f5f5;
              margin-top: 5%;

              @include respond-max($breakpoint-sm) {
                margin-bottom: 10%;
                margin-top: 10%;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                /* padding: 15px; */
                /* padding-top: 35px; */
                width: 90%;
                height: auto;
              }
            }

            .insurance-header-container {
              height: 140px;

              @include respond-max($breakpoint-xsm) {
                height: 112px;
              }

              .insurance-main-header-container {
                width: 400px;

                @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                  width: 210px;
                }

                h2 {
                }

                .h2-lighter {
                  font-weight: 300;
                }
              }

              .insurance-red-block-underline {
                width: 100px;
                height: 5px;
                background-color: red;
                margin-top: -10px;

                @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                  width: 65px;
                }
              }
            }

            .insurance-paragraph-container {
              margin-top: 30px;
              width: 34%;

              @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                margin-top: -30px;
              }

              @include respond-max($breakpoint-md) {
                width: 100%;
              }

              @include respond-range(993px, 1100px) {
                width: 45%;
              }

              .visibility-paragraph-container {
                min-height: 132px;
                padding-right: 40px;

                @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                  padding: 0;
                  min-height: 0;
                }

                @include respond-range(769px, $breakpoint-md) {
                  padding-right: 150px;
                }

                .insurance-small-paragraph {
                  color: $paragraph-font-gray;
                  font-family: $paragraph-font;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 29px;
                  /*@include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                                font-size: 13px;
                            }*/
                }
              }

              .insurance-paragraph-content {
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                min-width: 650px;

                @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                  width: 100%;
                  min-width: 0;
                  flex-direction: column;
                  align-items: center;
                }

                .insurance-paragraph {
                  width: 350px;
                  font-size: 20px;

                  @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                    width: 100%;
                  }
                }

                p:last-child {
                  margin-left: 20px;

                  @include respond-range($breakpoint-xsm, $breakpoint-sm) {
                    margin-top: 20px;
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }

        .services-title-photo {
          background-image: url("../images/servicesPageAssets/services-titleIns-photo@2x-min.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          background-position-y: bottom;
          height: 71vw;
          max-height: 985px;
          width: 50vw;
          max-width: 720px;
          z-index: 2;
          position: relative;
          bottom: 89%;
          -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
          float: right;

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            background-image: url("../images/servicesPageAssets/titlle-ins-photo-horizontal@2x-min.jpg");
            position: static;
            height: 60vw;
            width: 100%;
            max-width: none;
            order: -1;
            background-size: cover;
          }

          @include respond-min(1440px) {
            bottom: 895px;
          }
        }

        .card-container-main {
          width: 100%;
          height: 36vw;
          max-height: 519px;

          @include respond-max($breakpoint-md) {
            height: auto;
            max-height: none;
          }

          .offers-card-container {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            position: relative;
            z-index: 99;
            bottom: 225%;

            @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
              position: static;
              display: block;
            }

            @include respond-max($breakpoint-md) {
              bottom: 30px;
            }

            .title-products-we-of {
              height: 433px;
              width: 435px;
              border: 1px solid #f2f2f2;
              background-color: #ffffff;
              box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);

              @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                width: auto;
              }

              @include respond-range($breakpoint-sm, 815px) {
                display: flex !important;
                justify-content: center;
              }
            }

            .title-services-we-of {
              height: 433px;
              width: 435px;
              border: 1px solid #f2f2f2;
              background-color: #f5f5f5;
              box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.1);

              @include respond-max($breakpoint-sm) {
                box-shadow: none;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                width: auto;
              }

              @include respond-range($breakpoint-sm, 815px) {
                display: flex !important;
                justify-content: center;
              }
            }

            .tech-card-flex-display {
              display: flex;
              justify-content: center;

              @include respond-range($breakpoint-sm, 815px) {
                width: 90%;
              }

              .tech-card-content {
                width: 76%;
                height: auto;
                margin-top: 60px;

                .tech-product-tools-icon {
                  background-image: url("../images/servicesPageAssets/icon-title-products@2x.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  background-position-y: top;
                  height: 61px;
                  width: 67px;
                }

                .tech-services-tools-icon {
                  background-image: url("../images/servicesPageAssets/icon-title-services@2x.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  background-position-y: top;
                  height: 61px;
                  width: 67px;
                }

                h3 {
                  margin-top: 37px;
                  margin-bottom: 22px;
                  width: 329px;

                  @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                    font-size: 18px;
                  }
                }

                ul {
                  padding-left: 20px;

                  li {
                    font-family: $paragraph-font;
                    font-size: 16px;
                    color: #5c636e;
                    margin-bottom: 10px;
                    line-height: 19px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .closing-service-main-section {
      width: 100%;
      height: 65vw;
      max-height: 936px;
      display: flex;
      justify-content: center;
      background-color: #faf9f0;

      @include respond-range($breakpoint-xxsm, $breakpoint-md) {
        margin-top: 0;
        display: block;
        height: auto;
        max-height: 1000px;
      }

      .closing-service-content-container {
        width: 100%;
        max-width: 1440px;
        display: flex;
        justify-content: space-between;

        @include respond-max($breakpoint-md) {
          flex-direction: column;
        }

        .closing-service-visibility-container {
          min-height: 100px;
          padding-top: 17px;

          @include respond-max($breakpoint-md) {
            padding-top: 58px;
          }

          .image-section {
            background-color: #383d46;
            position: relative;
            width: 75%;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              width: 100%;
              position: static;
              min-height: 0;
              min-width: 0;
              background-color: #ffffff;
            }

            @include respond-range(993px, $breakpoint-lg) {
              /*min-height: 580px;
							min-width: 364px;*/
            }

            @include respond-range(1201px, $breakpoint-sml) {
              /*min-width: 454px;
						min-height: 578px;*/
            }

            .closing-img {
              background-image: url("../images/servicesPageAssets/services-closing-photo@3x-min.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              background-position-y: top;
              height: 48vw;
              width: 48vw;
              position: relative;
              bottom: -40px;

              @include respond-range(993px, $breakpoint-lg) {
                min-width: 505px;
                min-height: 505px;
              }

              @include respond-range($breakpoint-xxsm, $breakpoint-md) {
                position: static;
                width: 100%;
                height: 60vw;
                background-size: cover;
              }

              @include respond-range(1201px, $breakpoint-sml) {
                /*height: 521px;
						width: 521px;*/
              }

              @include respond-min($breakpoint-sml) {
                max-width: 691px;
                max-height: 691px;
              }
            }
          }
        }

        .services-closing-content {
          width: 53%;
          padding-top: 56px;

          @include respond-range($breakpoint-xxsm, $breakpoint-md) {
            width: 100%;
            padding-top: 0;
          }

          @include respond-range(993px, $breakpoint-lg) {
            width: 70%;
          }

          .content-text-card {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 37vw;
            max-height: 533px;
            background-color: #f5f5f5;
            width: 100%;

            @include respond-range($breakpoint-xxsm, $breakpoint-md) {
              min-height: 0;
              height: auto;
            }

            @include respond-range(993px, $breakpoint-lg) {
              min-width: 508px;
              min-height: 424px;
            }

            @include respond-range(1201px, $breakpoint-sml) {
              /*padding: 64px 21px 0 100px;*/
            }

            .closing-main-header-container {
              width: 72%;

              @include respond-max($breakpoint-md) {
                width: 90%;
                margin: 5% 0;
              }

              @include respond-max($breakpoint-sm) {
                margin: 10% 0;
              }

              @include respond-range(993px, 1100px) {
                width: 84%;
              }

              .h2-lighter {
                font-weight: 300;
              }

              .closing-paragraph {
                color: $paragraph-font-gray;
                font-family: $paragraph-font;
                font-size: 18px;
                font-weight: 500;
                line-height: 29px;
                margin-top: 30px;

                @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
                  padding: 0;
                  margin-top: 20px;
                  /*margin-bottom: 12px;*/
                }

                @include respond-range(769px, $breakpoint-md) {
                  padding: 0;
                  margin-top: 50px;
                }

                @include respond-range(993px, $breakpoint-lg) {
                  /*padding-right: 28px;*/
                }

                @include respond-range(1201, $breakpoint-sml) {
                  /*padding-right: 0;*/
                }
              }
            }
          }
        }
      }
    }

    .customer-service-main-section {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 88px;
      background: linear-gradient(180deg, $light-tan 0%, #ffffff 100%);

      @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 15px 0;
      }

      .h2-lighter {
        font-weight: 300;
      }

      .customer-content-body {
        text-align: center;
        padding-top: 60px;
        padding-bottom: 60px;
        width: 90%;
        max-width: 1440px;

        .customer-body-text {
          display: flex;
          justify-content: space-around;
          width: 100%;
          flex-wrap: wrap;
          margin-top: 60px;
          margin-bottom: 60px;

          @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
            display: block;
          }

          @include respond-range(769px, $breakpoint-md) {
            justify-content: space-between;
            flex-wrap: nowrap;
          }

          #middle-column {
            @include respond-range(769px, $breakpoint-md) {
              margin: 0 30px;
            }
          }

          .serv-text {
            width: 259px;
            text-align: left;

            @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
              padding: 0;
              width: 100%;
              margin-top: 30px;
            }

            @include respond-range(769px, $breakpoint-sml) {
              /*width: 34%;*/
            }

            .hd-txt {
              color: $title-font-blue;
              font-family: $paragraph-font;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: -0.33px;
              line-height: 29px;
              padding-bottom: 15px;
            }

            .hd-paragraph {
              color: $paragraph-font-gray;
              font-family: $paragraph-font;
              font-size: 16px;
              font-weight: 500;
              line-height: 29px;
            }
          }
        }

        .for-more-information-paragraph {
          color: $title-font-blue;
          font-family: $header-font;
          font-size: 32px;
          font-weight: 300;
          line-height: 44px;
          text-align: center;
          padding-top: 2%;
          padding-bottom: 55px;
          width: 725px;
          margin: 0 auto;

          @include respond-range($breakpoint-xxsm, $breakpoint-sm) {
            font-size: 25px;
            padding-left: 0%;
            padding-right: 0%;
            padding-top: 10%;
            padding-bottom: 10%;
          }

          @include respond-range($breakpoint-xxsm, 925px) {
            width: 90%;
          }
        }

        .button-section {
          width: 100%;
          display: flex;
          justify-content: center;

          .get-contact-us {
            background-color: $red-button;
            box-shadow: 6px 6px 0 0 $lighter-gray;
            color: #ffffff;
            text-transform: uppercase;
            cursor: pointer;
            height: 61px;
            width: 203px;
            font-size: 13px;
            font-family: $header-font;
            font-weight: bold;
            line-height: 21px;
            text-align: center;
            padding: 20px;
          }

          .get-contact-us:hover {
            background-color: $red-button-hover;
          }
        }
      }
    }
  }
}
