﻿@import "../modules/constants";
@import "../modules/mixins";

.modal {

	.header {
		.content {
			font-family: $header-font;
		}
	}

	.content {
		h3 {
			font-family: $paragraph-font;
		}
	}

	#message-content-container {
		padding: 2.1rem !important;
	}

	.actions-button-container {
		display: flex;
		justify-content: center;

		.close-button {
			background-color: $red-button;
			width: 130px;
			height: 42px;
			text-align: center;
			letter-spacing: -0.25px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #FFFFFF;
			font-family: $header-font;
			font-size: 13px;
			font-weight: bold;
		}

		.close-button:hover {
			background-color: $red-button-hover;
		}
	}

	#actions-button-container {
		@include respond-max(767px) {
			padding: 1rem 1rem !important;
		}
	}
}
